import ExerciseForm, {
  ExerciseFormValues,
} from "../components/ExerciseForm/ExerciseForm";
import { selectCourseOptionsByThemeId } from "../domain/e-learning/e-learning.selectors";
import { FileDragAndDropProvider } from "../components/DragAndDrop/DragAndDropContext";
import CheckboxInputGroup from "../components/CheckboxInputGroup/CheckboxInputGroup";
import { CreateExerciseActionPayload } from "../domain/e-learning/e-learning.types";
import ThemeHeaderCard from "../components/ThemeHeaderCard/ThemeHeaderCard";
import { createExercise } from "../domain/e-learning/e-learning.actions";
import StepNav, { Step } from "../components/StepBar/StepNav";
import FileUpload from "../components/FileUpload/FileUpload";
import IconButton from "../components/IconButton/IconButton";
import { generatePath, useParams } from "react-router-dom";
import LeftSidebar from "../components/Page/LeftSidebar";
import { FormProvider, useForm } from "react-hook-form";
import Collapse from "../components/Collapse/Collapse";
import { useDispatch, useSelector } from "react-redux";
import Content from "../components/Page/Content";
import Topbar from "../components/Page/Topbar";
import useNavigate from "../utils/useNavigate";
import { useTranslation } from "react-i18next";
import Paper from "../components/Paper/Paper";
import Field from "../components/Field/Field";
import Title from "../components/Page/Title";
import Page from "../components/Page/Page";
import Main from "../components/Page/Main";
import mapWithId from "../utils/mapWithId";
import { IoCreate } from "react-icons/io5";
import { FC, useMemo } from "react";
import "./AddExercise.scss";

const AddExercise: FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ themeId: string; exerciseId: string }>();
  const courses = useSelector(selectCourseOptionsByThemeId(params.themeId));
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const methods = useForm<ExerciseFormValues>({
    reValidateMode: "onChange",
    defaultValues: {
      courses: [],
    },
  });

  const onSubmit = (data: CreateExerciseActionPayload) => {
    dispatch(createExercise({ ...data, themeId: params.themeId }));
    navigate();
  };

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_edit_course",
          to: generatePath("/e-learning/:id/courses/add", {
            id: params.themeId,
          }),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", {
            id: params.themeId,
          }),
        },
      ]),
    [params],
  );

  return (
    <FormProvider {...methods}>
      <FileDragAndDropProvider>
        <Page>
          <Topbar className="add-exercise-topbar">
            <StepNav steps={steps} />
          </Topbar>
          <Main>
            <LeftSidebar className="add-exercise-left-sidebar">
              <ThemeHeaderCard id={params.themeId} />
              <Paper fullWidth className="add-exercise-course-selector">
                <Field
                  name="courses"
                  label={t("select_courses")}
                  registerOptions={{
                    required: "error_at_least_one_selected_element",
                  }}
                  fullWidth
                >
                  <CheckboxInputGroup
                    options={courses}
                    renderOption={(option) => (
                      <IconButton
                        to={`/e-learning/${params.themeId}/courses/${option.value}`}
                      >
                        <IoCreate />
                      </IconButton>
                    )}
                  />
                </Field>
              </Paper>
              <Paper fullWidth className="add-exercise-file-uploader">
                <Collapse title={t("theme_import-file-title")}>
                  <FileUpload
                    label={t("import_file")}
                    accept={"video/*"}
                    multiple
                  />
                </Collapse>
              </Paper>
            </LeftSidebar>
            <Content>
              <Title className="">{t("e_learning_add_exercises")}</Title>
              <ExerciseForm onSubmit={onSubmit} />
            </Content>
          </Main>
        </Page>
      </FileDragAndDropProvider>
    </FormProvider>
  );
};

export default AddExercise;
