import { cloneDeepWith, isNil, omitBy } from "lodash";
import { parse } from "papaparse";

export default function parseCsv<T, D>(
  data: string,
  transform: (data: D) => T,
): T[] {
  const results = parse<D>(data, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  });
  return results.data.map((item) =>
    cloneDeepWith(transform(item), (value) => omitBy(value, isNil)),
  );
}
