import { ComponentProps, forwardRef, Ref } from "react";
import classNames from "classnames";
import "./TextAreaInput.scss";

export interface TextAreaInputProps extends ComponentProps<"textarea"> {
  fullWidth?: boolean;
  color?: "default" | "error" | "success";
  ref?: Ref<HTMLTextAreaElement>;
}

const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
  (
    { fullWidth = false, color = "default", ...props }: TextAreaInputProps,
    ref,
  ) => {
    return (
      <textarea
        {...props}
        ref={ref}
        className={classNames(
          "text-area-input",
          {
            "text-area-input-full-width": fullWidth,
          },
          `text-area-input-${color}`,
        )}
      />
    );
  },
);

TextAreaInput.displayName = "TextAreaInput";

export default TextAreaInput;
