import { ComponentProps, forwardRef, Ref } from "react";
import classNames from "classnames";
import "./Input.scss";

export interface InputProps extends ComponentProps<"input"> {
  fullWidth?: boolean;
  color?: "default" | "error" | "success";
  ref?: Ref<HTMLInputElement>;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ fullWidth = false, color = "default", ...props }: InputProps, ref) => {
    return (
      <input
        {...props}
        className={classNames(
          "input",
          {
            "input-full-width": fullWidth,
          },
          `input-${color}`,
        )}
        ref={ref}
      />
    );
  },
);

Input.displayName = "Input";

export default Input;
