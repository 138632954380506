import {
  createContext,
  DragEvent,
  DragEventHandler,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface FileDragAndDropContextProps {
  children: ReactNode;
}

type FileDropHandler = (files: FileList) => void;

type FileDragAndDropContextType = {
  draggedFile: File | null;
  onFileDragStart: (file: File) => DragEventHandler;
  onFileDragOver: DragEventHandler;
  onFileDrop: (handleFileDrop: FileDropHandler) => DragEventHandler;
};

const FileDragAndDropContext = createContext<FileDragAndDropContextType>({
  draggedFile: null,
  onFileDragStart: () => () => {},
  onFileDragOver: () => {},
  onFileDrop: () => () => {},
});

export const useFileDragAndDrop = () => {
  const context = useContext(FileDragAndDropContext);

  return context;
};

export const FileDragAndDropProvider: FC<FileDragAndDropContextProps> = ({
  children,
}) => {
  const [draggedFile, setDraggedFile] = useState<File | null>(null);

  const onFileDragStart = useCallback(
    (file) => () => {
      setDraggedFile(file);
    },
    [],
  );

  const onFileDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
  }, []);

  const onFileDrop = useCallback(
    (handleFileDrop: FileDropHandler) => (event: DragEvent) => {
      event.preventDefault();

      if (draggedFile) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(draggedFile);
        handleFileDrop(dataTransfer.files);
      }
    },
    [draggedFile],
  );

  return (
    <FileDragAndDropContext.Provider
      value={{ onFileDragStart, onFileDragOver, onFileDrop, draggedFile }}
    >
      {children}
    </FileDragAndDropContext.Provider>
  );
};
