type RawSlide = {
  "Support complémentaire vidéo": string;
  "Support complémentaire picto": string;
  "Question 1": string;
  "Vidéo question 1": string;
  "Vidéo correction question 1": string;
  "Réponse A": string;
  "Vidéo réponse A": string;
  "Correction réponse A": boolean;
  "Réponse B": string;
  "Vidéo réponse B": string;
  "Correction réponse B": boolean;
  "Réponse C": string;
  "Vidéo réponse C": string;
  "Correction réponse C": boolean;
  "Réponse D": string;
  "Vidéo réponse D": string;
  "Correction réponse D": boolean;
};

type RawCombinedSlide = {
  "Support complémentaire vidéo": string;
  "Support complémentaire picto": string;
  "Question 1": string;
  "Vidéo question 1": string;
  "Vidéo correction question 1": string;
  "Question 2": string;
  "Vidéo question 2": string;
  "Vidéo correction question 2": string;
  "Réponse A": string;
  "Vidéo réponse A": string;
  "Correction réponse A": boolean;
  "Réponse B": string;
  "Vidéo réponse B": string;
  "Correction réponse B": boolean;
  "Réponse C": string;
  "Vidéo réponse C": string;
  "Correction réponse C": boolean;
  "Réponse D": string;
  "Vidéo réponse D": string;
  "Correction réponse D": boolean;
};

type Answer = {
  text: string;
  video: string;
  isCorrectAnswer: boolean;
};

type Question = {
  text: string;
  video: string;
  correctionVideo: string;
  answers: [Answer, Answer, Answer, Answer];
};

type CombinedQuestion = {
  text: string;
  video: string;
  correctionVideo: string;
  answers: [Answer, Answer];
};

type Slide = {
  picto: string;
  video: string;
  questions: [Question] | [CombinedQuestion, CombinedQuestion];
};

function mapAnswer(
  data: RawSlide | RawCombinedSlide,
  index: "A" | "B" | "C" | "D",
): Answer {
  return {
    text: data[`Réponse ${index}`],
    video: data[`Vidéo réponse ${index}`],
    isCorrectAnswer: data[`Correction réponse ${index}`],
  };
}

export default function mapSlide(data: RawSlide | RawCombinedSlide): Slide {
  let questions: Slide["questions"];

  const combined = Boolean(data["Question 2"]);

  if (combined) {
    questions = [
      {
        text: data["Question 1"],
        video: data["Vidéo question 1"],
        correctionVideo: data["Vidéo correction question 1"],
        answers: [mapAnswer(data, "A"), mapAnswer(data, "B")],
      },
      {
        text: data["Question2"],
        video: data["Vidéo question 2"],
        correctionVideo: data["Vidéo correction question 2"],
        answers: [mapAnswer(data, "C"), mapAnswer(data, "D")],
      },
    ];
  } else {
    questions = [
      {
        text: data["Question 1"],
        video: data["Vidéo question 1"],
        correctionVideo: data["Vidéo correction question 1"],
        answers: [
          mapAnswer(data, "A"),
          mapAnswer(data, "B"),
          mapAnswer(data, "C"),
          mapAnswer(data, "D"),
        ],
      },
    ];
  }

  return {
    picto: data["Support complémentaire picto"],
    video: data["Support complémentaire vidéo"],
    questions,
  };
}
