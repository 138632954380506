import { ComponentProps, forwardRef, Ref } from "react";
import FieldLabel from "../FieldLabel/FieldLabel";
import classNames from "classnames";
import "./CheckboxInput.scss";

export interface CheckboxInputProps extends ComponentProps<"input"> {
  label?: string;
  fullWidth?: boolean;
  color?: "default" | "error" | "success";
  ref?: Ref<HTMLInputElement>;
}

const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    { name, label, color = "default", fullWidth, ...props }: CheckboxInputProps,
    ref,
  ) => (
    <div
      className={classNames("checkbox-input-wrapper", {
        "checkbox-input-wrapper-full-width": fullWidth,
      })}
    >
      <input
        {...props}
        name={name}
        ref={ref}
        type="checkbox"
        className={classNames("checkbox-input", `checkbox-input-${color}`)}
      />
      <FieldLabel name={name} label={label} />
    </div>
  ),
);

CheckboxInput.displayName = "CheckboxInput";

export default CheckboxInput;
