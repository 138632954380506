import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useRef,
  useState,
} from "react";
import { useFileDragAndDrop } from "../DragAndDrop/DragAndDropContext";
import { IoDownloadOutline, IoTrashSharp } from "react-icons/io5";
import dragIcon from "../../../src/assets/icons/cross-drag.svg";
import IconButton from "../IconButton/IconButton";
import { useTranslation } from "react-i18next";
import "./FileUpload.scss";

interface FileUploadProps {
  label: string;
  accept: string;
  multiple?: boolean;
  hideFileList?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const appendFiles = (newFiles: File[]) => (prevFiles: File[]) => {
  const files = newFiles.reduce((acc, newFile) => {
    const exist = Boolean(
      acc.find((prevFile) => prevFile.name === newFile.name),
    );

    if (exist) return acc;

    return [...acc, newFile];
  }, prevFiles);

  return files;
};

const FileUpload = ({
  label,
  accept,
  multiple,
  hideFileList = false,
  onChange,
}: FileUploadProps) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [files, setFiles] = useState<File[]>([]);

  const { onFileDragStart } = useFileDragAndDrop();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles: File[] = event.target.files
      ? Array.from(event.target.files)
      : [];

    setFiles(multiple ? appendFiles(newFiles) : newFiles);

    if (onChange) {
      onChange(event);
    }
  };

  const handleDelete = useCallback(
    (name: string) => () => {
      setFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile.name !== name),
      );
    },
    [],
  );

  const onUpload = useCallback(() => {
    inputRef.current?.click();
  }, []);

  return (
    <div className="file-upload">
      <div
        className="file-upload-input"
        onClick={onUpload}
        onKeyDown={onUpload}
        role="button"
        tabIndex={-1}
      >
        <IoDownloadOutline size={32} />
        <p className="file-upload-input-label m-typography-body2">{t(label)}</p>
        <input
          ref={inputRef}
          hidden
          multiple={multiple}
          type="file"
          onChange={handleChange}
          accept={accept}
        />
      </div>
      {!hideFileList && files.length > 0 && (
        <div className="file-upload-field-list">
          <p>{t("theme_imported-files-title")}</p>
          {files.map((file) => {
            return (
              <div
                key={`${file.name}-${file.lastModified}`}
                className="file-upload-file-field"
                draggable="true"
                onDragStart={onFileDragStart(file)}
              >
                <div className="file-upload-file-infos">
                  <img src={dragIcon} alt="drag icon" />
                  <p className="file-upload-file-title">{file.name}</p>
                </div>
                <IconButton
                  color="dangerous"
                  onClick={handleDelete(file.name)}
                  size="small"
                >
                  <IoTrashSharp />
                </IconButton>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
