import { deleteDictionnaryEntry } from "../../domain/e-learning/e-learning.actions";
import { selectDictionnary } from "../../domain/e-learning/e-learning.selectors";
import { selectIsAdmin } from "../../domain/currentUser/currentUser.selectors";
import IconButton from "../../components/IconButton/IconButton";
import FieldLabel from "../../components/FieldLabel/FieldLabel";
import { useEffect, useState, ChangeEvent, FC } from "react";
import { IoCreate, IoTrashSharp } from "react-icons/io5";
import VideoPlayer from "../../utils/video/VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { DictionnaryEntry } from "./dictionnaryEntry";
import Button from "../../components/Button/Button";
import Paper from "../../components/Paper/Paper";
import Input from "../../components/Input/Input";
import { useTranslation } from "react-i18next";
import { AppState } from "../../redux/types";
import "./dictionnary.scss";

const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

type Result = {
  letter: string;
  dictionnaryEntries: DictionnaryEntry[];
};

function groupByFirstLetter(
  dictionnaryEntries: DictionnaryEntry[] = [],
): Result[] {
  return dictionnaryEntries.reduce<Result[]>((acc, dictionnaryEntry) => {
    const letter = dictionnaryEntry.word[0].toLowerCase();
    const index = acc.findIndex((result) => result.letter === letter);
    if (index === -1) {
      const result: Result = { letter, dictionnaryEntries: [dictionnaryEntry] };
      return [...acc, result];
    }
    const prevWords = acc[index]?.dictionnaryEntries || [];
    const result: Result = {
      letter,
      dictionnaryEntries: [...prevWords, dictionnaryEntry],
    };
    return [...acc.slice(0, index), result, ...acc.slice(index + 1)];
  }, []);
}

const Dictionnary: FC = () => {
  const { t } = useTranslation();
  const dictionnary = useSelector<AppState, DictionnaryEntry[] | undefined>(
    selectDictionnary,
  );
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  const [results, setResults] = useState<Result[]>(
    groupByFirstLetter(dictionnary),
  );
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [letterInputValue, setLetterInputValue] = useState<string>("");

  useEffect(() => {
    setResults(groupByFirstLetter(dictionnary));
  }, [dictionnary]);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLetterInputValue("");
    setSearchInputValue(event.target.value);
    if (dictionnary) {
      const searchQuery = event.target.value.trim();
      const searchResults = dictionnary.filter((wordWithDefinition) =>
        wordWithDefinition.word.match(new RegExp(searchQuery, "i")),
      );
      setResults(groupByFirstLetter(searchResults));
    }
  };

  const handleResetLetterInput = () => {
    setLetterInputValue("");
    if (dictionnary) {
      setResults(groupByFirstLetter(dictionnary));
    }
  };

  const handleLetterInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue("");
    setLetterInputValue(event.target.value);
    if (dictionnary) {
      setResults(
        groupByFirstLetter(
          dictionnary.filter((wordWithDefinition) =>
            wordWithDefinition.word
              .toLowerCase()
              .startsWith(event.target.value.toLowerCase()),
          ),
        ),
      );
    }
  };

  const handleDeleteEntry = (dictionnaryEntryId: string) => () => {
    dispatch(deleteDictionnaryEntry(dictionnaryEntryId));
  };

  return (
    <div className="dictionnary">
      {isAdmin && (
        <div className="dictionnary-actions">
          <Button to="/dictionnary/add">{t("add-a-word")}</Button>
        </div>
      )}
      <form className="m-form">
        <Paper className="m-form-fields" fullWidth>
          <FieldLabel name="searchId" label="search-word" />
          <Input
            name="searchId"
            value={searchInputValue}
            onChange={handleSearchInputChange}
            fullWidth
          />
          <FieldLabel name="searchId" label="search-with-letter" />
          <div className="dictionnary-form-field-letter">
            {alphabet.map((letter) => (
              <label
                htmlFor={letter}
                key={letter}
                className="dictionnary-form-field-letter-label"
              >
                <input
                  value={letter}
                  name={letter}
                  id={letter}
                  type="radio"
                  className="dictionnary-form-field-letter-input"
                  checked={letterInputValue === letter}
                  onChange={handleLetterInputChange}
                />
                {letter.toUpperCase()}
              </label>
            ))}
            <Button
              onClick={handleResetLetterInput}
              disabled={letterInputValue.length === 0}
            >
              {t("select-all-letters")}
            </Button>
          </div>
        </Paper>
      </form>
      <div className="dictionnary-letters">
        {results.map((result) => (
          <div className="dictionnary-letter" key={result.letter}>
            <div className="dictionnary-letter-title m-typography-h1 text-color-primary">
              {result.letter.toUpperCase()}
            </div>
            <div className="dictionnary-letter-words">
              {result.dictionnaryEntries.map((dictionnaryEntry) => (
                <div
                  className="dictionnary-letter-word"
                  key={dictionnaryEntry.word}
                >
                  <VideoPlayer
                    src={
                      dictionnaryEntry.videoId
                        ? `https://vod.api.video/vod/${dictionnaryEntry.videoId}/mp4/source.mp4`
                        : undefined
                    }
                    size="small"
                  />
                  <div className="dictionnary-letter-word-content">
                    <div className="dictionnary-letter-word-actions">
                      {isAdmin && (
                        <IconButton
                          color="dangerous"
                          onClick={handleDeleteEntry(dictionnaryEntry.id)}
                        >
                          <IoTrashSharp />
                        </IconButton>
                      )}
                      {isAdmin && (
                        <IconButton
                          to={`/dictionnary/edit/${dictionnaryEntry.id}`}
                        >
                          <IoCreate />
                        </IconButton>
                      )}
                    </div>
                    <h2 className="m-typography-h2">
                      {dictionnaryEntry.word[0].toUpperCase()}
                      {dictionnaryEntry.word.slice(1)}
                    </h2>
                    <p className="m-typography-body2">
                      {dictionnaryEntry.definition[0].toUpperCase()}
                      {dictionnaryEntry.definition.slice(1)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Dictionnary;
