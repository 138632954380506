import {
  CreateExerciseActionPayload,
  UpdateExerciseActionPayload,
} from "../../domain/e-learning/e-learning.types";
import { FileDragAndDropProvider } from "../DragAndDrop/DragAndDropContext";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import validateFileSize from "../Field/validateFile";
import ArrayField from "../ArrayField/ArrayField";
import FileUpload from "../FileUpload/FileUpload";
import { useFormContext } from "react-hook-form";
import FileField from "../FileInput/FileField";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import parseCsv from "../../utils/parseCsv";
import mapSlide from "../../utils/mapSlide";
import { ChangeEvent, FC } from "react";
import Button from "../Button/Button";
import Field from "../Field/Field";
import Paper from "../Paper/Paper";
import Input from "../Input/Input";
import "./ExerciseForm.scss";

export type ExerciseFormValues =
  | CreateExerciseActionPayload
  | UpdateExerciseActionPayload;

interface ExerciseFormProps {
  themeId?: string;
  onSubmit: (data: ExerciseFormValues) => void;
}

const ExerciseForm: FC<ExerciseFormProps> = ({ onSubmit }) => {
  const params = useParams<{ themeId: string; exerciseId: string }>();

  const { t } = useTranslation();

  const { handleSubmit, reset } = useFormContext<ExerciseFormValues>();

  const onPublish = (data: ExerciseFormValues) => {
    onSubmit({
      ...data,
      themeId: params.themeId,
      isPublished: true,
    });
  };

  const onFileLoad = (event: ProgressEvent<FileReader>) => {
    if (event.target) {
      const data = event.target.result;
      if (data) {
        const slides = parseCsv(data.toString(), mapSlide);
        reset({ slides, courses: [] });
      }
    }
  };

  const onFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files.item(0);
      if (file) {
        const reader = new FileReader();
        reader.onload = onFileLoad;
        reader.readAsText(file);
      }
    }
  };

  return (
    <form className="m-form" onSubmit={handleSubmit(onSubmit)}>
      <Paper fullWidth className="m-form-fields">
        <div className="m-form-fields-content">
          <FileDragAndDropProvider>
            <FileUpload
              label="Importer un fichier CSV"
              accept=".csv"
              onChange={onFileUpload}
            />
          </FileDragAndDropProvider>
        </div>
      </Paper>
      <Paper fullWidth className="m-form-fields">
        <div className="m-form-fields-content">
          <Field
            name="title"
            label="title"
            registerOptions={{ required: "field_required" }}
            fullWidth
          />
        </div>
        <div className="m-form-fields-content">
          <ArrayField
            name="slides"
            addButtonLabel="e_learning_add_slide"
            itemTitle="slide"
            fullWidth
            maxItems={40}
          >
            <Field name="id" registerOptions={{}}>
              <Input hidden />
            </Field>
            <FileField
              name="videoId"
              inputName="video"
              label="e_learning_video_field"
              registerOptions={{
                required: "field_required",
                validate: validateFileSize,
              }}
              fullWidth
              type="video"
            />
            <FileField
              name="pictoName"
              inputName="picto"
              label="e_learning_picto_field"
              registerOptions={{
                required: "field_required",
                validate: validateFileSize,
              }}
              fullWidth
              type="image"
            />
            <ArrayField
              name="questions"
              addButtonLabel="e_learning_add_question"
              itemTitle="question"
              fullWidth
              maxItems={2}
            >
              <Field name="id" registerOptions={{}}>
                <Input hidden />
              </Field>
              <Field
                name="text"
                registerOptions={{ required: "field_required" }}
                fullWidth
              />
              <FileField
                name="videoId"
                inputName="video"
                label="e_learning_video_field"
                registerOptions={{
                  required: "field_required",
                  validate: validateFileSize,
                }}
                fullWidth
                type="video"
              />
              <FileField
                name="correctionVideoId"
                inputName="correctionVideo"
                label="e_learning_correction_video_field"
                registerOptions={{
                  required: "field_required",
                  validate: validateFileSize,
                }}
                fullWidth
                type="video"
              />
              <ArrayField
                name="answers"
                itemTitle="e_learning_answer"
                addButtonLabel="e_learning_add_answer"
                maxItems={4}
                fullWidth
              >
                <Field name="id" registerOptions={{}}>
                  <Input hidden />
                </Field>
                <Field name="isCorrectAnswer" registerOptions={{}} fullWidth>
                  <CheckboxInput label="e_learning_correct_answer" />
                </Field>
                <Field
                  name="text"
                  registerOptions={{ required: "field_required" }}
                  fullWidth
                />
                <FileField
                  name="videoId"
                  inputName="video"
                  label="e_learning_video_field"
                  registerOptions={{
                    required: "field_required",
                    validate: validateFileSize,
                  }}
                  fullWidth
                  type="video"
                />
              </ArrayField>
            </ArrayField>
          </ArrayField>
        </div>
      </Paper>
      <Paper fullWidth className="m-form-actions">
        <Button onClick={handleSubmit(onPublish)}>{t("publish")}</Button>
        <Button type="submit">{t("save")}</Button>
        <Button
          to={`/e-learning?theme="${params.themeId}"`}
          color="dangerous"
          variant="outlined"
        >
          {t("cancel")}
        </Button>
      </Paper>
    </form>
  );
};

export default ExerciseForm;
