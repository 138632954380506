import {
  ICourse,
  IExercise,
  IExerciseBeneficiary,
  ISession,
} from "./e-learning.types";

export const reorder = (
  items: ICourse[],
  order: number,
  isUp: boolean,
): ICourse[] => {
  if (isUp && order > 0) {
    const firstItemToMutate = items.find(
      (item) => item.order === order,
    ) as ICourse;
    const secondItemToMutate = items.find(
      (item) => item.order === order - 1,
    ) as ICourse;
    firstItemToMutate.order -= 1;
    secondItemToMutate.order += 1;
  } else if (!isUp && order < items.length - 1) {
    const firstItemToMutate = items.find(
      (item) => item.order === order,
    ) as ICourse;
    const secondItemToMutate = items.find(
      (item) => item.order === order + 1,
    ) as ICourse;
    firstItemToMutate.order += 1;
    secondItemToMutate.order -= 1;
  }
  return items;
};

export const updateBlocsOrderAfterDelete = (
  blocs: (ICourse | IExercise)[],
  deleteBlocId: string,
): ICourse[] | IExercise[] => {
  const deleteBloc = blocs.find((bloc) => bloc.id === deleteBlocId) as
    | ICourse
    | IExercise;
  const newBlocs = blocs.filter((bloc) => bloc.id !== deleteBlocId);
  const blocOrderDeleted = deleteBloc.order;
  return newBlocs.map((bloc) => {
    if (bloc.order > blocOrderDeleted) {
      return { ...bloc, order: bloc.order - 1 };
    }
    return { ...bloc };
  });
};

export const updatePublishingStatusBlocs = (
  items: (ICourse | IExercise)[] = [],
  isPublished: boolean,
): ICourse[] => items.map((item) => ({ ...item, isPublished }));

export const updateStateExcercisesBeneficiary = (
  exercisesBeneficiaryState: IExerciseBeneficiary[],
  updatedExerciseBeneficiary: IExerciseBeneficiary,
): IExerciseBeneficiary[] => {
  const exerciseBeneficiaryToUpdate: IExerciseBeneficiary | undefined =
    exercisesBeneficiaryState.find(
      (exerciseBeneficiary: IExerciseBeneficiary) =>
        exerciseBeneficiary.id === updatedExerciseBeneficiary.id,
    );

  return exercisesBeneficiaryState.map((exerciseBeneficiary) => {
    if (exerciseBeneficiary.id === exerciseBeneficiaryToUpdate?.id) {
      return { ...updatedExerciseBeneficiary };
    }
    return exerciseBeneficiary;
  });
};

export const getExercisesFromSession = (
  session: ISession,
  currentExercises: IExercise[],
): IExercise[] => {
  if (
    session?.exercises &&
    session?.exercises.length > 0 &&
    session?.exercisesBeneficiary &&
    session?.exercisesBeneficiary.length > 0
  ) {
    return session?.exercises
      .map((exercise) => {
        const correspondingExercisesBeneficiary =
          session?.exercisesBeneficiary?.find(
            ({ exerciseId }) => exerciseId === exercise.id,
          );
        return {
          ...exercise,
          order: correspondingExercisesBeneficiary?.order as number,
        };
      })
      .sort((a, b) => a.order - b.order);
  }
  return currentExercises;
};
