import { useTranslation } from "react-i18next";
import "./FieldLabel.scss";

export interface FieldLabelProps {
  label?: string;
  required?: boolean;
  name?: string;
}

export default function FieldLabel({
  label,
  name,
  required = false,
}: FieldLabelProps) {
  const { t } = useTranslation();

  if (!label) return null;

  return (
    <label htmlFor={name} className="field-label">
      {t(label)}
      {required ? ` (${t("required")})` : null}
    </label>
  );
}
