import { UseControllerProps, useFormContext, useWatch } from "react-hook-form";
import { ComponentProps, DragEventHandler, useEffect, useState } from "react";
import createVideoSrtUrl from "../../utils/createVideoSrtUrl";
import { useFieldContext } from "../ArrayField/ArrayField";
import VideoPlayer from "../../utils/video/VideoPlayer";
import createVideoUrl from "../../utils/createVideoUrl";
import { ErrorMessage } from "@hookform/error-message";
import { IoDocumentOutline } from "react-icons/io5";
import FieldLabel from "../FieldLabel/FieldLabel";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Input from "../Input/Input";
import "./FileField.scss";

type FileFieldType = "image" | "video" | "srt";

interface FilePreviewProps
  extends Pick<ComponentProps<"input">, "src" | "alt"> {
  type?: FileFieldType;
}

function FilePreview({ src, type, alt }: FilePreviewProps) {
  switch (type?.split("/")[0]) {
    case "image":
      return <img src={src} alt={alt} className="file-preview-image" />;
    case "video":
      return <VideoPlayer src={src} size="x-small" />;
    default:
      return <IoDocumentOutline className="file-preview-other" />;
  }
}

const accepts = {
  image: "image/png, image/jpeg",
  video: "video/*",
  srt: ".srt",
};

export interface FileFieldProps {
  fullWidth?: boolean;
  color?: "default" | "error" | "success";
  name: string;
  inputName: string;
  registerOptions: UseControllerProps["rules"];
  label?: string;
  required?: boolean;
  helperText?: string;
  onDragOver?: DragEventHandler<HTMLFieldSetElement>;
  onDrop?: DragEventHandler<HTMLFieldSetElement>;
  type: FileFieldType;
}

export default function FileField({
  name,
  inputName,
  registerOptions,
  fullWidth,
  required,
  label,
  helperText,
  type,
}: FileFieldProps) {
  const { t } = useTranslation();

  const previewContext = useFieldContext({ name });

  const inputContext = useFieldContext({ name: inputName });

  const {
    getFieldState,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const previewValue: string = useWatch({
    name: previewContext.name,
  });

  const inputValue: FileList | string = useWatch({
    name: inputContext.name,
    defaultValue: "",
  });

  const [preview, setPreview] = useState<FilePreviewProps | null>(null);

  useEffect(() => {
    if (previewValue && typeof inputValue === "string") {
      if (type === "image") {
        const src = watch(inputContext.name);
        const alt = previewValue;
        setPreview({ src, alt, type });
      } else if (type === "video") {
        const src = createVideoUrl(previewValue);
        const alt = src.substring(src.lastIndexOf("/") + 1);
        setPreview({ src, alt, type });
      } else if (type === "srt") {
        const src = createVideoSrtUrl(previewValue);
        const alt = src.substring(src.lastIndexOf("/") + 1);
        setPreview({ src, alt, type });
      } else {
        setPreview(null);
      }
    } else if (inputValue instanceof FileList) {
      const file = inputValue.item(0);
      if (file) {
        const src = URL.createObjectURL(file);
        const alt = src.substring(src.lastIndexOf("/") + 1);
        setPreview({ src, alt, type });
      } else {
        setPreview(null);
      }
    } else {
      setPreview(null);
    }
  }, [inputContext.name, inputValue, previewValue, type, watch]);

  return (
    <fieldset
      className={classNames("file-field", {
        "file-field-full-width": fullWidth,
      })}
    >
      <FieldLabel name={inputContext.name} required={required} label={label} />
      <div className="file-field-input">
        {preview && (
          <div className="file-preview">
            <FilePreview {...preview} />
          </div>
        )}
        <Input
          type="file"
          accept={accepts[type]}
          color={getFieldState(inputContext.name).error ? "error" : "default"}
          {...register(inputContext.name, {
            ...registerOptions,
            required: registerOptions?.required && !watch(previewContext.name),
          })}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={inputContext.name}
        render={({ message }) => (
          <p className="file-field-error-message">{t(message)}</p>
        )}
      />
      {helperText && <p className="file-field-helper-text">{t(helperText)}</p>}
      {!label && required && (
        <p className="file-field-helper-text">{t("required")}</p>
      )}
    </fieldset>
  );
}
