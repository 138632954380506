import patternEmailAddress from "../../../../components/Field/patternEmailAddress";
import { selectInfosToBeneficiary } from "../../../../domain/auth/auth.selectors";
import AdminCardPermission from "./admin-card-permission/admin-card-permission";
import { IPersonalInformations } from "../../../../domain/auth/auth.types";
import Button from "../../../../components/Button/Button";
import Field from "../../../../components/Field/Field";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import "./add-admin-card.scss";
import { FC } from "react";

const AddAdminCard: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const infosBeneficiary: IPersonalInformations = useSelector(
    selectInfosToBeneficiary,
  );
  useForm<IPersonalInformations>({
    defaultValues: infosBeneficiary,
    criteriaMode: "all",
    shouldFocusError: false,
  });
  return (
    <div className="add-admin-container">
      <div className="add-admin-first-line">
        <h3 className="add-admin-title">{t("add_admin")}</h3>
      </div>
      <form className="m-form">
        <div className="form-group">
          <Field
            name="lastName"
            label="lastName"
            registerOptions={{ required: "field_required" }}
          />
        </div>
        <div className="form-group">
          <Field
            name="firstName"
            label="firstName"
            registerOptions={{ required: "field_required" }}
          />
        </div>
        <div className="form-group large">
          <Field
            name="email"
            label="email"
            registerOptions={{
              required: "field_required",
              pattern: patternEmailAddress,
            }}
          />
        </div>
        <div className="form-group full-size">
          <AdminCardPermission
            editMode
            accessChatbot={false}
            modifySupport={false}
            createAdmin={false}
            pedagogicSupervision={false}
          />
        </div>
      </form>
      <div className="manage-actions">
        <Button type="submit">{t("publish")}</Button>
        <Button
          onClick={() => {
            history.goBack();
          }}
          color="dangerous"
        >
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};

export default AddAdminCard;
