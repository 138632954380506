import {
  ComponentProps,
  FC,
  forwardRef,
  ReactNode,
  Ref,
  useState,
} from "react";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import "../../components/RadioInput/RadioInput.scss";
import classNames from "classnames";
import "./CheckboxInputGroup.scss";

export type CheckboxInputGroupOption = {
  label?: string;
  value: string;
} & Record<string, unknown>;

export interface CheckboxInputGroupProps extends ComponentProps<"input"> {
  label?: string;
  options?: CheckboxInputGroupOption[];
  renderOption?: (option: CheckboxInputGroupOption) => ReactNode;
  defaultValue?: string[];
  ref?: Ref<HTMLInputElement>;
  color?: "default" | "error" | "success";
}

const CheckboxInputGroup: FC<CheckboxInputGroupProps> = forwardRef<
  HTMLInputElement,
  CheckboxInputGroupProps
>(
  (
    {
      options = [],
      renderOption,
      className,
      defaultValue = [],
      ...props
    }: CheckboxInputGroupProps,
    ref,
  ) => {
    const [values, setValues] = useState(defaultValue);

    const toggle = (value: string) => () => {
      const newValues = [...values];

      if (newValues.length > 0) {
        const index = newValues.findIndex((newValue) => newValue === value);

        if (index === -1) {
          newValues.push(value);
        } else {
          newValues.splice(index, 1);
        }
      } else {
        newValues.push(value);
      }

      setValues(newValues);
    };

    return (
      <div className={classNames("checkbox-input-group", className)}>
        {options.map((option) => (
          <div key={option.value} className="checkbox-input-wrapper">
            <CheckboxInput
              {...props}
              id={option.value}
              checked={values?.some((checked) => checked === option.value)}
              ref={ref}
              value={option.value}
              onChange={toggle(option.value)}
              label={option.label}
            />
            <div className="checkbox-input-right-content">
              {renderOption && renderOption(option)}
            </div>
          </div>
        ))}
      </div>
    );
  },
);

CheckboxInputGroup.displayName = "CheckboxInputGroup";

export default CheckboxInputGroup;
