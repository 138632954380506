import { SuccessOrErrorState } from "./interactionsWithUsers.types";
import { AppState } from "../../redux/types";
import { createSelector } from "reselect";

const selectSuccessOrErrorState = (state: AppState) => state.successOrError;

export const selectSnackBar = createSelector(
  selectSuccessOrErrorState,
  (successOrError: SuccessOrErrorState) => successOrError.snackbar,
);
