import { RegisterOptions } from "react-hook-form";

const fileMaxSize = 150 * 1024 * 1024;

const validateFileSize: RegisterOptions["validate"] = (value: FileList) => {
  const size = value[0]?.size;
  if (size > fileMaxSize) {
    return "file_size_exceeded";
  }
  return true;
};

export default validateFileSize;
