import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FC, useEffect } from "react";

import {
  IBeneficiary,
  IPersonalInformations,
} from "../../../../domain/auth/auth.types";
import { selectCurrentUser } from "../../../../domain/currentUser/currentUser.selectors";
import CheckboxInput from "../../../../components/CheckboxInput/CheckboxInput";
import { createBeneficiary } from "../../../../domain/auth/auth.actions";
import Button from "../../../../components/Button/Button";
import Field from "../../../../components/Field/Field";
import Paper from "../../../../components/Paper/Paper";
import Input from "../../../../components/Input/Input";
import "./personal-informations.scss";

const PersonalInformations: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const methods = useForm<IPersonalInformations>({
    reValidateMode: "onChange",
    defaultValues: user.personalInfos,
  });
  useEffect(() => {
    const handleCreate = async () => {
      const userProfile = await keycloak.loadUserProfile();
      if (userProfile) {
        const data: IBeneficiary = {
          kcId: user.kcId || keycloak.subject,
          email: userProfile.email,
          status: "infos",
        };
        dispatch(createBeneficiary(data));
      }
    };
    handleCreate();
  }, [dispatch, keycloak, user.kcId]);

  const handleUpdate = async (personalInfos: IPersonalInformations) => {
    const userProfile = await keycloak.loadUserProfile();
    if (userProfile) {
      const data: IBeneficiary = {
        kcId: user.kcId || keycloak.subject,
        email: userProfile.email,
        status: "registered",
        personalInfos,
      };
      dispatch(createBeneficiary(data));

      history.push("/register/payment");
    }
  };

  const handleReset = () => {
    methods.reset(user.personalInfos);
  };

  return (
    <FormProvider {...methods}>
      <section className="auth-personal-informations">
        <form
          className="m-form auth-personal-informations-form"
          onSubmit={methods.handleSubmit(handleUpdate)}
        >
          <Paper className="m-form-fields" fullWidth>
            <div className="m-form-fields-content m-form-fields-content-col-2 auth-personal-informations-form-fields-content">
              <Field
                name="lastName"
                label="lastName"
                registerOptions={{ required: "field_required" }}
                required
              />
              <Field
                name="firstName"
                label="firstName"
                registerOptions={{ required: "field_required" }}
                required
              />
              <Field
                name="address"
                label="address"
                registerOptions={{ required: "field_required" }}
                required
              />
              <Field
                name="zipcode"
                label="zipcode"
                registerOptions={{
                  required: "field_required",
                  pattern: {
                    value: /[0-9]{5}/,
                    message: t("invalid_format_zipcode"),
                  },
                }}
                required
              />
              <Field
                name="city"
                label="city"
                registerOptions={{ required: "field_required" }}
                required
              />
              <Field
                name="birthDate"
                label="birthDate"
                registerOptions={{ required: "field_required" }}
                required
              >
                <Input type="date" />
              </Field>
            </div>
            <div className="m-form-fields-content">
              <Field
                name="gc"
                registerOptions={{ required: true }}
                helperText="gc_helper_text"
                fullWidth
              >
                <CheckboxInput />
              </Field>
            </div>
          </Paper>
          <Paper className="m-form-actions" fullWidth>
            <Button type="submit" className="m-form-action-main">
              {t("validate")}
            </Button>
            <Button color="dangerous" onClick={handleReset}>
              {t("cancel")}
            </Button>
          </Paper>
        </form>
      </section>
    </FormProvider>
  );
};
export default PersonalInformations;
