import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from "react-hook-form";
import IconButton from "../IconButton/IconButton";
import FieldLabel from "../FieldLabel/FieldLabel";
import { IoTrashSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Collapse from "../Collapse/Collapse";
import Button from "../Button/Button";
import classNames from "classnames";
import "./ArrayField.scss";

export type FieldContextType = {
  disabled?: boolean;
  name?: string;
};

export const FieldContext = createContext<FieldContextType>({});

export function useFieldContext(initialContext?: FieldContextType) {
  const context = useContext(FieldContext);
  const enhancedContext = useMemo(
    () => ({
      name: [context.name, initialContext?.name].filter(Boolean).join("."),
      disabled: initialContext?.disabled || context.disabled,
    }),
    [context, initialContext],
  );
  return enhancedContext;
}

export interface ArrayFieldItemProps {
  index: number;
  title: string;
  remove: UseFieldArrayRemove;
  disabled?: boolean;
  name: string;
  children?: ReactNode;
}

function ArrayFieldItem({
  index,
  title,
  remove,
  disabled,
  name,
  children,
}: ArrayFieldItemProps) {
  const { t } = useTranslation();

  const onRemove = useCallback(() => remove(index), [remove, index]);

  return (
    <Collapse
      title={
        title && (
          <p className="array-field-item-title">
            {t(title, {
              index: index + 1,
              alphaIndex: String.fromCharCode(index + "A".charCodeAt(0)),
            })}
          </p>
        )
      }
    >
      <div className="array-field-item">
        <div className="array-field-item-header">
          <IconButton
            color="dangerous"
            size="medium"
            onClick={onRemove}
            disabled={disabled}
          >
            <IoTrashSharp />
          </IconButton>
        </div>
        <div className="array-field-item-fields">
          <FieldContext.Provider
            value={{
              name: `${name}.[${index}]`,
              disabled: disabled,
            }}
          >
            {children}
          </FieldContext.Provider>
        </div>
      </div>
    </Collapse>
  );
}

export interface ArrayFieldProps {
  name: string;
  label?: string;
  children?: ReactNode;
  required?: boolean;
  itemTitle?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  addButtonLabel?: string;
  maxItems?: number;
}

export default function ArrayField({
  label,
  name,
  children,
  required,
  itemTitle = "#{{index}}",
  fullWidth = false,
  disabled = false,
  addButtonLabel = "add",
  maxItems,
}: ArrayFieldProps) {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const context = useFieldContext({ name, disabled });

  const { fields, remove, append } = useFieldArray({
    control,
    name: context.name,
  });

  const onAdd = () => {
    append({});
  };

  const itemsLimitReached = useMemo(
    () => Boolean(maxItems && fields.length >= maxItems),
    [fields.length, maxItems],
  );

  return (
    <div
      className={classNames("array-field", {
        "array-field-full-width": fullWidth,
      })}
    >
      <FieldLabel label={label} name={context.name} required={required} />
      {fields.map((field, index) => (
        <ArrayFieldItem
          key={field.id}
          name={context.name}
          index={index}
          title={itemTitle}
          remove={remove}
          disabled={context.disabled}
        >
          {children}
        </ArrayFieldItem>
      ))}
      {!itemsLimitReached && (
        <Button onClick={onAdd} disabled={context.disabled} variant="outlined">
          {t(addButtonLabel)}
        </Button>
      )}
    </div>
  );
}
