import RadioInput, { RadioInputProps } from "../RadioInput/RadioInput";
import { FC, forwardRef, ReactNode, Ref } from "react";
import classNames from "classnames";
import "./RadioInputGroup.scss";

export type RadioInputGroupOption = {
  label?: string;
  value: string;
} & Record<string, unknown>;

export interface RadioInputGroupProps extends RadioInputProps {
  options?: RadioInputGroupOption[];
  renderOption?: (option: RadioInputGroupOption) => ReactNode;
  ref?: Ref<HTMLInputElement>;
  controlled?: boolean;
}

const RadioInputGroup: FC<RadioInputGroupProps> = forwardRef<
  HTMLInputElement,
  RadioInputGroupProps
>(
  (
    {
      options = [],
      renderOption,
      className,
      disabled,
      controlled = false,
      ...props
    }: RadioInputGroupProps,
    ref,
  ) => {
    return (
      <div className={classNames("radio-input-group", className)}>
        {options.map((option) => {
          return (
            <RadioInput
              checked={controlled ? props.value === option.value : undefined}
              {...props}
              key={option.value || option.label}
              ref={ref}
              id={option.value || option.label}
              type="radio"
              value={option.value}
              label={option.label}
              disabled={disabled || !option.value}
            >
              {renderOption && renderOption(option)}
            </RadioInput>
          );
        })}
      </div>
    );
  },
);

RadioInputGroup.displayName = "RadioInputGroup";

export default RadioInputGroup;
