import { Link, NavLink, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect } from "react";

import {
  selectRole,
  selectRoutes,
} from "../../../domain/currentUser/currentUser.selectors";
import { selectThemes } from "../../../domain/e-learning/e-learning.selectors";
import ThemeCard from "../themes/theme-card/theme-card";

import {
  getThemes,
  getBeneficiaryThemes,
  setAddMode,
  resetTheme,
  resetSession,
} from "../../../domain/e-learning/e-learning.actions";

import addThemeExercice from "../../../assets/icons/add-theme-exercise.svg";
import themeExercice from "../../../assets/icons/theme-exercise.svg";
import addThemeIcon from "../../../assets/icons/add-button.svg";
import arrowBack from "../../../assets/icons/arrow-back.svg";

import createVideoSrtUrl from "../../../utils/createVideoSrtUrl";
import createVideoUrl from "../../../utils/createVideoUrl";
import "./exercises.scss";

const Exercises: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const role = useSelector(selectRole);
  const routes = useSelector(selectRoutes);
  const currentRoute = routes?.find((route) => route.path === url);

  const themes = useSelector(selectThemes);
  const sortedThemes = themes.slice().sort((a, b) => {
    const matchA = a.title.match(/\d+/);
    const matchB = b.title.match(/\d+/);
    const serieANumber = matchA ? parseInt(matchA[0], 10) : 0;
    const serieBNumber = matchB ? parseInt(matchB[0], 10) : 0;
    return serieANumber - serieBNumber;
  });
  useEffect(() => {
    dispatch(resetSession());
    dispatch(resetTheme());
  }, [dispatch]);

  useEffect(() => {
    if (role !== "user") {
      dispatch(getThemes());
    } else {
      dispatch(getBeneficiaryThemes());
    }
  }, [dispatch, role]);

  const renderHeader = () => (
    <div className="themes-header">
      <img
        className="themes-image"
        src={currentRoute?.image}
        alt={currentRoute?.alt}
      />
    </div>
  );

  const addSupport = () => {
    dispatch(setAddMode(true));
    dispatch(resetTheme());
  };

  const renderAddThemeCard = () => (
    <div className="col col-md-6 col-md-4 col-lg-4 col-xl-4 mb-5 d-flex justify-content-center">
      <div className="add-theme-wrapper">
        <Link
          to="/e-learning/add-exercise"
          className="button-add theme-card-add add-theme-card theme-round-add-card"
          role="button"
          onClick={addSupport}
          aira-label={t("creation_theme")}
          style={{
            backgroundImage: `url(${addThemeIcon})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      </div>
    </div>
  );
  return (
    <>
      <h1 className="title-hidden">{t("h1_themes")}</h1>
      <section className="themes">
        {role !== "user" && renderHeader()}
        <div className="themes-container">
          <div className="themes-actions">
            <NavLink
              exact
              to={role !== "user" ? "/e-learning" : "/"}
              className="themes-link"
              role="button"
            >
              <img src={arrowBack} alt="revenir en arrière" />
            </NavLink>
            <span className="themes-link indication">
              <img
                src={role !== "user" ? addThemeExercice : themeExercice}
                alt="page actuelle"
              />
            </span>
          </div>
          {role === "user" && themes.length === 0 ? (
            <div className="themes-empty">
              <h2>{t("no_theme_available")}</h2>
            </div>
          ) : (
            <ul
              className={`themes-list ${(themes?.length as number) > 2 ? "space-between" : ""}`}
            >
              <div className="row theme-list">
                {role !== "user" && renderAddThemeCard()}
                {sortedThemes.map((theme) => (
                  <div
                    className="col col-md-6 col-lg-6 col-xl-4 mb-5 d-flex justify-content-center"
                    key={theme.id}
                  >
                    <ThemeCard
                      id={theme.id}
                      icon={theme.pictoName}
                      path={`${url}/${theme.id}`}
                      src={createVideoUrl(theme.videoId)}
                      subtitle={createVideoSrtUrl(theme.videoId)}
                      alt={theme.alt}
                      isBeneficiary={role === "user"}
                    />
                  </div>
                ))}
              </div>
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default Exercises;
