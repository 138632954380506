import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";

export const CREATE_THEME = "CREATE_THEME";
export const CREATE_THEME_SUCCESS = "CREATE_THEME_SUCCESS";
export const CREATE_THEME_FAILURE = "CREATE_THEME_FAILURE";

export const GET_THEMES = "GET_THEMES";
export const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS";
export const GET_THEMES_FAILURE = "GET_THEMES_FAILURE";

export const GET_DICTIONNARY_ENTRIES = "GET_DICTIONNARY_ENTRIES";
export const GET_DICTIONNARY_ENTRIES_SUCCESS =
  "GET_DICTIONNARY_ENTRIES_SUCCESS";
export const GET_DICTIONNARY_ENTRIES_FAILURE =
  "GET_DICTIONNARY_ENTRIES_FAILURE";

export const GET_THEME_AND_COURSES = "GET_THEME_AND_COURSES";
export const GET_THEME_AND_COURSES_SUCCESS = "GET_THEME_AND_COURSES_SUCCESS";
export const GET_THEME_AND_COURSES_FAILURE = "GET_THEME_AND_COURSES_FAILURE";

export const GET_THEME_AND_EXERCISES = "GET_THEME_AND_EXERCISES";
export const GET_THEME_AND_EXERCISES_SUCCESS =
  "GET_THEME_AND_EXERCISES_SUCCESS";
export const GET_THEME_AND_EXERCISES_FAILURE =
  "GET_THEME_AND_EXERCISES_FAILURE";

export const GET_BENEFICIARY_THEMES = "GET_BENEFICIARY_THEMES";
export const GET_BENEFICIARY_THEMES_SUCCESS = "GET_BENEFICIARY_THEMES_SUCCESS";
export const GET_BENEFICIARY_THEMES_FAILURE = "GET_BENEFICIARY_THEMES_FAILURE";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILURE = "GET_COURSES_FAILURE";

export const RESET_THEME = "RESET_THEME";

export const DELETE_THEME = "DELETE_THEME";
export const DELETE_THEME_SUCCESS = "DELETE_THEME_SUCCESS";
export const DELETE_THEME_FAILURE = "DELETE_THEME_FAILURE";

export const UPDATE_THEME = "UPDATE_THEME";
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS";
export const UPDATE_THEME_FAILURE = "UPDATE_THEME_FAILURE";

export const SET_ADD_MODE = "SET_ADD_MODE";

export const CREATE_COURSES = "CREATE_COURSES";
export const CREATE_COURSES_SUCCESS = "CREATE_COURSES_SUCCESS";
export const CREATE_COURSES_FAILURE = "CREATE_COURSES_FAILURE";

export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";

export const UPDATE_COURSE = "UPDATE_COURSE";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILURE = "UPDATE_COURSE_FAILURE";

export const PUBLISH_COURSE = "PUBLISH_COURSE";
export const PUBLISH_COURSE_SUCCESS = "PUBLISH_COURSE_SUCCESS";
export const PUBLISH_COURSE_FAILURE = "PUBLISH_COURSE_FAILURE";

export const ADD_DICTIONNARY_ENTRY = "ADD_DICTIONNARY_ENTRY";
export const ADD_DICTIONNARY_ENTRY_SUCCESS = "ADD_DICTIONNARY_ENTRY_SUCCESS";
export const ADD_DICTIONNARY_ENTRY_FAILURE = "ADD_DICTIONNARY_ENTRY_FAILURE";

export const DELETE_DICTIONNARY_ENTRY = "DELETE_DICTIONNARY_ENTRY";
export const DELETE_DICTIONNARY_ENTRY_SUCCESS =
  "DELETE_DICTIONNARY_ENTRY_SUCCESS";
export const DELETE_DICTIONNARY_ENTRY_FAILURE =
  "DELETE_DICTIONNARY_ENTRY_FAILURE";

export const UPDATE_DICTIONNARY_ENTRY = "UPDATE_DICTIONNARY_ENTRY";
export const UPDATE_DICTIONNARY_ENTRY_SUCCESS =
  "UPDATE_DICTIONNARY_ENTRY_SUCCESS";
export const UPDATE_DICTIONNARY_ENTRY_FAILURE =
  "UPDATE_DICTIONNARY_ENTRY_FAILURE";

export const REORDER_COURSE = "REORDER_COURSE";
export const REORDER_EXERCISE = "REORDER_EXERCISE";

export const PUBLISH_ALL_COURSES = "PUBLISH_ALL_COURSES";
export const PUBLISH_ALL_COURSES_SUCCESS = "PUBLISH_ALL_COURSES_SUCCESS";
export const PUBLISH_ALL_COURSES_FAILURE = "PUBLISH_ALL_COURSES_FAILURE";

export const PUBLISH_ALL_EXERCISES = "PUBLISH_ALL_EXERCISES";
export const PUBLISH_ALL_EXERCISES_SUCCESS = "PUBLISH_ALL_EXERCISES_SUCCESS";
export const PUBLISH_ALL_EXERCISES_FAILURE = "PUBLISH_ALL_EXERCISES_FAILURE";

export const UPDATE_EXERCISE = "UPDATE_EXERCISE";
export const UPDATE_EXERCISE_SUCCESS = "UPDATE_EXERCISE_SUCCESS";
export const UPDATE_EXERCISE_FAILURE = "UPDATE_EXERCISE_FAILURE";

export const DELETE_EXERCISE = "DELETE_EXERCISE";
export const DELETE_EXERCISE_SUCCESS = "DELETE_EXERCISE_SUCCESS";
export const DELETE_EXERCISE_FAILURE = "DELETE_EXERCISE_FAILURE";

export const CREATE_EXERCISE = "CREATE_EXERCISE";
export const CREATE_EXERCISE_SUCCESS = "CREATE_EXERCISE_SUCCESS";
export const CREATE_EXERCISE_FAILURE = "CREATE_EXERCISE_FAILURE";

export const GET_BENEFICIARY_EXERCISES = "GET_BENEFICIARY_EXERCISES";
export const GET_BENEFICIARY_EXERCISES_SUCCESS =
  "GET_BENEFICIARY_EXERCISES_SUCCESS";
export const GET_BENEFICIARY_EXERCISES_FAILURE =
  "GET_BENEFICIARY_EXERCISES_FAILURE";

export const GET_BENEFICIARY_PRACTICE_SESSION =
  "GET_BENEFICIARY_PRACTICE_SESSION";
export const GET_BENEFICIARY_PRACTICE_SESSION_SUCCESS =
  "GET_BENEFICIARY_PRACTICE_SESSION_SUCCESS";
export const GET_BENEFICIARY_PRACTICE_SESSION_FAILURE =
  "GET_BENEFICIARY_PRACTICE_SESSION_FAILURE";

export const GET_BENEFICIARY_EXAM_SESSION = "GET_BENEFICIARY_EXAM_SESSION";
export const GET_BENEFICIARY_EXAM_SESSION_SUCCESS =
  "GET_BENEFICIARY_EXAM_SESSION_SUCCESS";
export const GET_BENEFICIARY_EXAM_SESSION_FAILURE =
  "GET_BENEFICIARY_EXAM_SESSION_FAILURE";

export const RESET_SESSION = "RESET_SESSION";

export const UPDATE_EXERCISE_BENEFICIARY = "UPDATE_EXERCISE_BENEFICIARY";
export const UPDATE_EXERCISE_BENEFICIARY_SUCCESS =
  "UPDATE_EXERCISE_BENEFICIARY_SUCCESS";
export const UPDATE_EXERCISE_BENEFICIARY_FAILURE =
  "UPDATE_EXERCISE_BENEFICIARY_FAILURE";

export const GET_BENEFICIARY_PRACTICE_SESSION_SCORE =
  "GET_BENEFICIARY_PRACTICE_SESSION_SCORE";
export const GET_BENEFICIARY_PRACTICE_SESSION_SCORE_SUCCESS =
  "GET_BENEFICIARY_PRACTICE_SESSION_SCORE_SUCCESS";
export const GET_BENEFICIARY_PRACTICE_SESSION_SCORE_FAILURE =
  "GET_BENEFICIARY_PRACTICE_SESSION_SCORE_FAILURE";

export const GET_BENEFICIARY_EXAM_SESSION_SCORE =
  "GET_BENEFICIARY_EXAM_SESSION_SCORE";
export const GET_BENEFICIARY_EXAM_SESSION_SCORE_SUCCESS =
  "GET_BENEFICIARY_EXAM_SESSION_SCORE_SUCCESS";
export const GET_BENEFICIARY_EXAM_SESSION_SCORE_FAILURE =
  "GET_BENEFICIARY_EXAM_SESSION_SCORE_FAILURE";

export type Video = {
  id?: string;
  videoId?: string;
  vtt?: string;
  vttName?: string;
  title?: string;
  mp4?: string;
  thumbnail?: string;
};

export type ICourse = {
  id: string;
  themeId?: string;
  video?: string;
  videoId?: string;
  title?: string;
  description?: string;
  order: number;
  isPublished?: boolean;
  picto?: string;
  pictoName?: string;
  dictionnary?: DictionnaryEntry[];
  exercises?: IExercise[];
};

export type IExercise = {
  id: string;
  themeId?: string;
  isPublished?: boolean;
  title?: string;
  courses?: string[];
  slides: ISlide[];
  order: number;
};

export type ISlide = {
  id: string;
  exerciseId?: string;
  questions?: IQuestion[];
  videoId?: string;
  video?: string;
  picto?: string;
  pictoName?: string;
  order: number;
};

export type IQuestion = {
  id?: string;
  exerciseId?: string;
  video?: string;
  videoId?: string;
  text?: string;
  answers?: IAnswer[];
  correctionVideoId?: string;
  correctionVideo?: string;
};

export type IAnswer = {
  id?: string;
  questionId?: string;
  text?: string;
  isCorrectAnswer?: boolean;
  videoId?: string;
  video?: string;
};

export type ISession = {
  id: string;
  score?: string;
  themeId?: string;
  userId?: string;
  isFinished?: boolean;
  counter?: number;
  exercisesBeneficiary?: IExerciseBeneficiary[];
  exercises?: IExercise[];
};

export type IExerciseBeneficiary = {
  id: string;
  practiceSessionId?: string;
  exerciseId?: string;
  order: number;
  isCorrect?: boolean;
  isDone?: boolean;
  answersBeneficiary?: IAnswerBeneficiary[];
};

export type IAnswerBeneficiary = {
  id: string;
  exerciseBeneficiaryId?: string;
  answerId?: string;
  selected?: boolean;
  isCorrectAnswer?: boolean;
};

export type ISessionScore = {
  score: number;
  length: number;
  errors: number;
  improvement: number;
  themeToWatch?: ITheme[];
};

export type IModuleContainer = {
  id: string;
  order: number;
};

export type IAddModule = {
  id?: string;
  themeId: string;
  order: number;
};

export type IReorder = {
  items: ICourse[] | IExercise[];
  order: number;
  isUp: boolean;
};

export type ITheme = {
  id: string;
  alt: string;
  picto?: string;
  pictoName?: string;
  video?: string;
  videoId?: string;
  title: string;
  courses?: ICourse[];
  exercises?: IExercise[];
  srt?: string;
};

export interface ELearningState {
  themes: ITheme[];
  theme: ITheme;
  session: ISession;
  sessionScore: ISessionScore;
  isAddMode: boolean;
  loading: boolean;
  scoreLoading: boolean;
  errors?: string;
  dictionnary?: DictionnaryEntry[];
}

export type CreateThemeActionPayload = Omit<
  ITheme,
  "id" | "picto" | "video"
> & {
  picto?: FileList | string;
  video?: FileList | string;
  isPublished?: boolean;
};

export type UpdateThemeActionPayload = Omit<
  ITheme,
  "id" | "picto" | "video"
> & {
  picto?: FileList | string;
  video?: FileList | string;
  isPublished?: boolean;
};

export type CreateCoursesActionPayload = {
  themeId: string;
  courses: Array<
    Omit<
      ICourse,
      "id" | "picto" | "video" | "exercises" | "themeId" | "isPublished"
    > & {
      picto?: FileList | string;
      video?: FileList | string;
      srt?: FileList | string;
    }
  >;
  isPublished: boolean;
};

export type UpdateCourseActionPayload = Omit<
  ICourse,
  "id" | "picto" | "video" | "exercises"
> & {
  picto?: FileList | string;
  video?: FileList | string;
  srt?: FileList | string;
};

export type CreateExerciseActionPayload = Omit<
  IExercise,
  "id" | "slides" | "courses"
> & {
  courses: string[];
  slides: Array<
    Omit<ISlide, "picto" | "video" | "questions"> & {
      picto: FileList | string;
      video: FileList | string;
      questions: Array<
        Omit<IQuestion, "answers" | "video" | "correctionVideo"> & {
          video: FileList | string;
          correctionVideo: FileList | string;
          answers: Array<
            Omit<IAnswer, "video"> & {
              video: FileList | string;
            }
          >;
        }
      >;
    }
  >;
};

export type UpdateExerciseActionPayload = Omit<
  IExercise,
  "id" | "slides" | "courses"
> & {
  courses: string[];
  slides: Array<
    Omit<ISlide, "picto" | "video" | "questions"> & {
      picto: FileList | string;
      video: FileList | string;
      questions: Array<
        Omit<IQuestion, "answers" | "video" | "correctionVideo"> & {
          video: FileList | string;
          correctionVideo: FileList | string;
          answers: Array<
            Omit<IAnswer, "video"> & {
              video: FileList | string;
            }
          >;
        }
      >;
    }
  >;
};

export interface CreateThemeActionType {
  type: string;
  theme: CreateThemeActionPayload;
}
export interface CreateThemeSuccessActionType {
  type: string;
  theme: ITheme;
}

export interface CreateThemeFailureActionType {
  type: string;
  message: string;
}

export interface GetThemesActionType {
  type: string;
}

export interface GetThemesSuccessActionType {
  type: string;
  themes: ITheme[];
}

export interface GetThemesFailureActionType {
  type: string;
  message: string;
}

export interface UpdateThemeActionType {
  type: string;
  id: string;
  theme: UpdateThemeActionPayload;
}

export interface DeleteCourseActionType {
  type: string;
  id: string;
}

export interface UpdateThemeSuccessActionType {
  type: string;
  theme: ITheme;
}

export interface UpdateThemeFailureActionType {
  type: string;
  message: string;
}

export interface ResetThemeActionType {
  type: string;
}

export interface DeleteThemeActionType {
  type: string;
  id: string;
}

export interface DeleteThemeSuccessActionType {
  type: string;
  theme: ITheme;
}

export interface DeleteThemeFailureActionType {
  type: string;
  message: string;
}

export interface CreateCoursesActionType extends CreateCoursesActionPayload {
  type: string;
}

export interface CreateCoursesSuccessActionType {
  type: string;
  courses: ICourse[];
}

export interface CreateCoursesFailureActionType {
  type: string;
  message: string;
}

export interface CreateExerciseActionType {
  type: string;
  exercise: CreateExerciseActionPayload;
}

export interface CreateExerciseSuccessActionType {
  type: string;
  exercise: IExercise;
}

export interface CreateExerciseFailureActionType {
  type: string;
  message: string;
}

export interface UpdateCourseActionType {
  type: string;
  id: string;
  course: UpdateCourseActionPayload;
}

export interface UpdateCourseSuccessActionType {
  type: string;
  course: ICourse;
}

export interface UpdateCourseFailureActionType {
  type: string;
  message: string;
}

export interface DeleteCourseActionType {
  type: string;
  id: string;
}

export interface DeleteCourseSuccessActionType {
  type: string;
  course: ICourse;
}

export interface DeleteCourseFailureActionType {
  type: string;
  message: string;
}
export interface PublishCourseActionType {
  type: string;
  id: string;
}

export interface PublishCourseSuccessActionType {
  type: string;
  course: ICourse;
}

export interface PublishCourseFailureActionType {
  type: string;
  message: string;
}

export interface GetDictionnaryEntriesSuccessActionType {
  type: string;
  dictionnaryEntries: DictionnaryEntry[];
}

export interface GetDictionnaryEntriesFailureActionType {
  type: string;
  message: string;
}

export interface GetThemeAndElementsSuccessActionType {
  type: string;
  theme: ITheme;
}

export interface GetThemeAndElementsFailureActionType {
  type: string;
  message: string;
}

export interface DeleteDictionnaryEntryActionType {
  type: string;
  dictionnaryEntryId: string;
}

export interface DeleteDictionnaryEntrySuccessActionType {
  type: string;
  dictionnaryEntryId: string;
}

export interface DeleteDictionnaryEntryFailureActionType {
  type: string;
  message: string;
}

export interface AddDictionnaryEntryActionType {
  type: string;
  dictionnaryEntry: DictionnaryEntry;
}

export interface AddDictionnaryEntrySuccessActionType {
  type: string;
  dictionnaryEntry: DictionnaryEntry;
}

export interface AddDictionnaryEntryFailureActionType {
  type: string;
  message: string;
}

export interface UpdateDictionnaryEntryActionType {
  type: string;
  dictionnaryEntry: DictionnaryEntry;
}

export interface UpdateDictionnaryEntrySuccessActionType {
  type: string;
  dictionnaryEntry: DictionnaryEntry;
}

export interface UpdateDictionnaryEntryFailureActionType {
  type: string;
  message: string;
}

export interface SetAddModeActionType {
  type: string;
  value: boolean;
}

export interface PublishAllCourseActionType {
  type: string;
  isPublished: boolean;
  themeId: string;
}

export interface PublishAllCourseSuccessActionType {
  type: string;
  isPublished: boolean;
}

export interface PublishAllCourseFailureActionType {
  type: string;
  message: string;
}
export interface PublishAllExercisesActionType {
  type: string;
  isPublished: boolean;
  themeId: string;
}

export interface PublishAllExercisesSuccessActionType {
  type: string;
  isPublished: boolean;
}

export interface PublishAllExercisesFailureActionType {
  type: string;
  message: string;
}

export interface GetCoursesActionType {
  type: string;
  themeId: string;
}

export interface GetCoursesSuccessActionType {
  type: string;
  courses: ICourse[];
}

export interface GetCoursesFailureActionType {
  type: string;
  message: string;
}

export interface AddExerciseContainerSuccessActionType {
  type: string;
  exercise: IModuleContainer;
}
export interface AddExerciseContainerFailureActionType {
  type: string;
  message: string;
}

export type UpdateExerciseActionType = {
  type: string;
  id: string;
  exercise: UpdateExerciseActionPayload;
};

export type UpdateExerciseSuccessActionType = {
  type: string;
  exercise: Partial<IExercise>;
};

export type UpdateQuestionActionType = {
  type: string;
  question: Partial<IQuestion>;
};

export type UpdateQuestionSuccessActionType = {
  type: string;
  question: Partial<IQuestion>;
};
export type UpdateAnswerActionType = {
  type: string;
  answer: Partial<IAnswer>;
};

export type UpdateAnswerSuccessActionType = {
  type: string;
  answer: Partial<IAnswer>;
};

export interface DeleteExerciseActionType {
  type: string;
  id: string;
}
export interface DeleteExerciseSuccessActionType {
  type: string;
  id: string;
}

export interface DeleteExerciseFailureActionType {
  type: string;
  message: string;
}

export interface GetBeneficiaryExercisesActionType {
  type: string;
  id: string;
}
export interface GetBeneficiaryExercisesSuccessActionType {
  type: string;
  exercises: IExercise[];
}
export interface GetBeneficiaryExercisesFailureActionType {
  type: string;
  message: string;
}

export interface GetBeneficiaryPracticeSessionActionType {
  type: string;
  userId: string;
  themeId: string;
}
export interface GetBeneficiaryPracticeSessionSuccessActionType {
  type: string;
  session: ISession;
}
export interface GetBeneficiaryPracticeSessionFailureActionType {
  type: string;
  message: string;
}

export interface GetBeneficiaryExamSessionActionType {
  type: string;
  userId: string;
}
export interface GetBeneficiaryExamSessionSuccessActionType {
  type: string;
  session: ISession;
}
export interface GetBeneficiaryExamSessionFailureActionType {
  type: string;
  message: string;
}

export interface ResetSessionActionType {
  type: string;
}

export type UpdateExerciseBeneficiaryActionType = {
  type: string;
  exerciseBeneficiary: Partial<IExerciseBeneficiary>;
};

export type UpdateExerciseBeneficiarySuccessActionType = {
  type: string;
  exerciseBeneficiary: Partial<IExerciseBeneficiary>;
};

export type UpdateExerciseBeneficiaryFailureActionType = {
  type: string;
  message: string;
};

export type GetBeneficiaryPracticeSessionScore = {
  type: string;
  id: string;
};

export type GetBeneficiaryPracticeSessionScoreSuccess = {
  type: string;
  sessionScore: Partial<ISessionScore>;
};

export type GetBeneficiaryPracticeSessionScoreFailure = {
  type: string;
  message: string;
};

export type GetBeneficiaryExamSessionScore = {
  type: string;
  id: string;
};

export type GetBeneficiaryExamSessionScoreSuccess = {
  type: string;
  sessionScore: Partial<ISessionScore>;
};

export type GetBeneficiaryExamSessionScoreFailure = {
  type: string;
  message: string;
};

export interface ELearningActionsType {
  type: string;
  id?: string;
  theme?: ITheme;
  themes?: ITheme[];
  courses?: ICourse[];
  course?: ICourse;
  message?: string;
  value?: boolean;
  courseContainer?: IAddModule;
  exercise?: IExercise;
  exercises?: IExercise[];
  exerciseContainer?: IAddModule;
  reorder?: IReorder;
  isPublished?: boolean;
  themeId?: string;
  questions?: IQuestion[];
  question?: Partial<IQuestion>;
  answer?: IAnswer;
  session?: ISession;
  userId?: string;
  exerciseBeneficiary?: Partial<IExerciseBeneficiary>;
  sessionScore?: ISessionScore;
  dictionnaryEntry?: DictionnaryEntry;
  dictionnaryEntries?: DictionnaryEntry[];
  dictionnaryEntryId?: string;
}
