import {
  CreateThemeActionPayload,
  UpdateThemeActionPayload,
} from "../../domain/e-learning/e-learning.types";
import { DefaultValues, FormProvider, useForm } from "react-hook-form";
import validateFileSize from "../Field/validateFile";
import FileField from "../FileInput/FileField";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Button from "../Button/Button";
import Field from "../Field/Field";
import Paper from "../Paper/Paper";
import { Mode } from "../../types";
import Title from "../Page/Title";
import { FC } from "react";
import "./ThemeForm.scss";

export type ThemeFormValues =
  | UpdateThemeActionPayload
  | CreateThemeActionPayload;

interface ThemeFormProps {
  mode: Mode;
  title: string;
  onSubmit: (data: ThemeFormValues) => void;
  defaultValues?: DefaultValues<ThemeFormValues>;
}

const ThemeForm: FC<ThemeFormProps> = ({
  mode,
  title,
  onSubmit,
  defaultValues = {},
}) => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const methods = useForm<ThemeFormValues>({
    reValidateMode: "onChange",
    defaultValues,
  });

  const onPublish = (data: ThemeFormValues) => {
    onSubmit({ ...data, isPublished: !defaultValues.isPublished });
  };

  return (
    <FormProvider {...methods}>
      <form className="m-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Title className="">{t(title)}</Title>
        <Paper fullWidth className="m-form-fields">
          <div className="m-form-fields-content">
            <Field
              name="title"
              label="e_learning_title_field"
              registerOptions={{ required: "field_required" }}
              fullWidth
            />
            <FileField
              name="pictoName"
              inputName="picto"
              label="e_learning_picto_field"
              registerOptions={{
                required: "field_required",
                validate: validateFileSize,
              }}
              fullWidth
              type="image"
            />
            <Field
              label="e_learning_alt_field"
              name="alt"
              registerOptions={{
                required: "field_required",
              }}
              fullWidth
            />
            <FileField
              name="videoId"
              inputName="video"
              label="e_learning_video_field"
              registerOptions={{
                required: "field_required",
                validate: validateFileSize,
              }}
              fullWidth
              type="video"
            />
          </div>
        </Paper>
        <Paper fullWidth className="m-form-actions">
          <Button onClick={methods.handleSubmit(onPublish)}>
            {defaultValues && defaultValues.isPublished
              ? t("unpublish")
              : t("publish")}
          </Button>
          <Button type="submit">{t("save")}</Button>
          <Button
            to={`/e-learning/${params.id}/courses/add`}
            className="m-form-action-main"
            disabled={mode === "add"}
          >
            {t("e_learning_add_courses")}
          </Button>
          <Button color="dangerous" variant="outlined" to="/e-learning">
            {t("cancel")}
          </Button>
        </Paper>
      </form>
    </FormProvider>
  );
};

export default ThemeForm;
