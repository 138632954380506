import { selectThemeById } from "../../domain/e-learning/e-learning.selectors";
import IconButton from "../IconButton/IconButton";
import { IoCreateOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Paper from "../Paper/Paper";
import "./ThemeHeaderCard.scss";
import { FC } from "react";

interface ThemeHeaderCardProps {
  id?: string;
}

const ThemeHeaderCard: FC<ThemeHeaderCardProps> = ({ id }) => {
  const theme = useSelector(selectThemeById(id));
  const { t } = useTranslation();
  if (!id || !theme) return null;
  return (
    <Paper fullWidth className="theme-header-card">
      <p className="m-typography-body2">{t("theme_title")}</p>
      <div className="theme-header-card-content">
        <img
          src={theme.picto}
          alt={theme.pictoName}
          className="theme-header-card-picto"
        />
        <h3 className="theme-header-card-title m-typography-h3">
          {theme.title}
        </h3>
        <IconButton to={`/e-learning/${id}/edit`}>
          <IoCreateOutline />
        </IconButton>
      </div>
    </Paper>
  );
};

export default ThemeHeaderCard;
