import {
  ComponentProps,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
} from "react";
import useNavigate from "../../utils/useNavigate";
import { LocationDescriptor } from "history";
import classNames from "classnames";
import "./IconButton.scss";

type Size = "small" | "medium" | "large";
const sizes: Size[] = ["small", "medium", "large"];

type Color = "primary" | "dangerous";
const colors: Color[] = ["primary", "dangerous"];

export interface IconButtonProps extends ComponentProps<"button"> {
  size?: Size;
  color?: Color;
  disabled?: boolean;
  children?: ReactNode;
  to?: LocationDescriptor;
}

const IconButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  IconButtonProps
> = (
  {
    size = "medium",
    color = "primary",
    disabled = false,
    children,
    className,
    to,
    ...props
  },
  ref,
) => {
  const navigate = useNavigate(to);

  return (
    <button
      type="button"
      role={to ? "link" : "button"}
      onClick={navigate}
      {...props}
      ref={ref}
      className={classNames(
        "m-icon-button",
        colors.includes(color)
          ? `m-icon-button-${color}`
          : "m-icon-button-primary",
        sizes.includes(size) ? `m-icon-button-${size}` : "m-icon-button-medium",
        className,
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default forwardRef(IconButton);
