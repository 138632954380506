import { FC, ReactNode } from "react";
import classNames from "classnames";
import "./Page.scss";

interface PageProps {
  children: ReactNode;
}

const Page: FC<PageProps> = ({ children }) => (
  <div className={classNames("main")}>{children}</div>
);

export default Page;
