import DeleteThemeConfirmationDialog from "../../components/DeleteThemeConfirmationDialog/DeleteThemeConfirmationDialog";
import RadioInputGroup, {
  RadioInputGroupOption,
} from "../../components/RadioInputGroup/RadioInputGroup";
import {
  selectLoading,
  selectThemes,
} from "../../domain/e-learning/e-learning.selectors";
import { getThemes } from "../../domain/e-learning/e-learning.actions";
import useQueryParamsState from "../../utils/useQueryParamsState";
import IconButton from "../../components/IconButton/IconButton";
import FieldLabel from "../../components/FieldLabel/FieldLabel";
import LeftSidebar from "../../components/Page/LeftSidebar";
import { ChangeEvent, FC, useEffect, useMemo } from "react";
import { IoAddCircle, IoCreate } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import Content from "../../components/Page/Content";
import Topbar from "../../components/Page/Topbar";
import Paper from "../../components/Paper/Paper";
import Theme from "../../components/Theme/Theme";
import Title from "../../components/Page/Title";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import Main from "../../components/Page/Main";
import "./ELearning.scss";

const ELearning: FC = () => {
  const { t } = useTranslation();
  const themes = useSelector(selectThemes);
  const loading = useSelector(selectLoading);
  const themeOptions: RadioInputGroupOption[] = useMemo(
    () => themes.map((theme) => ({ label: theme.title, value: theme.id })),
    [themes],
  );
  const [theme, setTheme] = useQueryParamsState("theme", "");
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loading) {
      dispatch(getThemes());
    }
  }, [dispatch, loading]);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTheme = event.target.value || "";
    setTheme(newTheme);
  };
  return (
    <Page>
      <Topbar>
        <Title>Formations</Title>
        <Button
          to="/e-learning/add"
          startIcon={<IoAddCircle />}
          variant="outlined"
        >
          {t("Ajouter une thématique")}
        </Button>
      </Topbar>
      <Main>
        <LeftSidebar>
          <Paper className="e-learning-theme-selector" fullWidth>
            <FieldLabel label="Thématiques" />
            <RadioInputGroup
              label="Thématiques"
              name="theme"
              options={themeOptions}
              controlled
              value={theme}
              onChange={onChange}
              renderOption={(option) => {
                const disabled = !option.value;
                return (
                  <div className="e-learning-theme-selector-option-actions">
                    <IconButton
                      disabled={disabled}
                      to={`/e-learning/${option.value}/edit`}
                    >
                      <IoCreate />
                    </IconButton>
                    <DeleteThemeConfirmationDialog
                      id={option.value}
                      disabled={disabled}
                    />
                  </div>
                );
              }}
            />
          </Paper>
        </LeftSidebar>
        <Content>
          <Theme id={theme} />
        </Content>
      </Main>
    </Page>
  );
};

export default ELearning;
