import { generatePath, Route, Switch, useParams } from "react-router-dom";
import StepNav, { Step } from "../../components/StepBar/StepNav";
import LeftSidebar from "../../components/Page/LeftSidebar";
import Button from "../../components/Button/Button";
import Content from "../../components/Page/Content";
import Topbar from "../../components/Page/Topbar";
import Input from "../../components/Input/Input";
import EditTheme from "../EditTheme/EditTheme";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import Main from "../../components/Page/Main";
import mapWithId from "../../utils/mapWithId";
import EditExercise from "../EditExercise";
import AddExercise from "../AddExercise";
import EditCourse from "../EditCourse";
import AddCourse from "../AddCourse";
import { FC, useMemo } from "react";
import "./Theme.scss";

const Theme: FC = () => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", params),
        },
        {
          label: "e_learning_add_courses",
          to: generatePath("/e-learning/:id/courses/add", params),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", params),
        },
      ]),
    [params],
  );
  return (
    <Switch>
      <Route path="/e-learning/:id/edit" exact>
        <Page>
          <Topbar className="theme-topbar">
            <StepNav steps={steps} />
          </Topbar>
          <Main>
            <LeftSidebar className="theme-left-sidebar">
              <p className="m-typography-body1">{t("share_content")}</p>
              <Input disabled />
              <Button disabled>{t("add")}</Button>
            </LeftSidebar>
            <Content>
              <EditTheme />
            </Content>
          </Main>
        </Page>
      </Route>
      <Route path="/e-learning/:themeId/courses/add" exact>
        <AddCourse />
      </Route>
      <Route path="/e-learning/:themeId/courses/:courseId" exact>
        <EditCourse />
      </Route>
      <Route path="/e-learning/:themeId/exercises/add" exact>
        <AddExercise />
      </Route>
      <Route path="/e-learning/:themeId/exercises/:exerciseId" exact>
        <EditExercise />
      </Route>
    </Switch>
  );
};

export default Theme;
