import {
  IoCardOutline,
  IoCheckmarkCircle,
  IoNewspaperOutline,
  IoPersonOutline,
} from "react-icons/io5";
import PersonalInformations from "../../presentation/auth/steps/personal-informations/personal-informations";
import ResumeRegister from "../../presentation/auth/steps/resume-register/resume-register";
import StepNav, { Step } from "../../components/StepBar/StepNav";
import Payment from "../../presentation/payment/payment";
import Content from "../../components/Page/Content";
import { Route, Switch } from "react-router-dom";
import Page from "../../components/Page/Page";
import Main from "../../components/Page/Main";
import mapWithId from "../../utils/mapWithId";
import { FC } from "react";
import "./Register.scss";

const steps: Step[] = mapWithId([
  {
    label: "account",
    to: "/register",
    icon: <IoPersonOutline />,
    disabled: true,
  },
  { label: "infos", to: "/register/infos", icon: <IoNewspaperOutline /> },
  { label: "payment", to: "/register/payment", icon: <IoCardOutline /> },
  { label: "success", to: "/register/success", icon: <IoCheckmarkCircle /> },
]);

const Register: FC = () => {
  return (
    <Page>
      <Main>
        <Content>
          <StepNav steps={steps} />
          <div className="register-content">
            <Switch>
              <Route path="/register/infos" component={PersonalInformations} />
              <Route path="/register/payment" component={Payment} />
              <Route path="/register/success" component={ResumeRegister} />
            </Switch>
          </div>
        </Content>
      </Main>
    </Page>
  );
};

export default Register;
