import { ComponentProps, forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./RadioInput.scss";

export interface RadioInputProps extends ComponentProps<"input"> {
  fullWidth?: boolean;
  label?: string;
  children?: ReactNode;
}

const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ children, label, fullWidth, ...props }: RadioInputProps, ref) => {
    const { t } = useTranslation();

    return (
      <div
        className={classNames("radio-input-wrapper", {
          "radio-input-wrapper-full-width": fullWidth,
        })}
      >
        <input ref={ref} type="radio" className="radio-input" {...props} />
        {label && (
          <label htmlFor={props.id} className="m-form-label">
            {t(label)}
          </label>
        )}
        {children}
      </div>
    );
  },
);

RadioInput.displayName = "RadioInput";

export default RadioInput;
