import PortalCard from "../../components/PortalCard/PortalCard";
import Content from "../../components/Page/Content";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import Main from "../../components/Page/Main";
import { Redirect } from "react-router-dom";
import { FC } from "react";
import "./Portal.scss";

const Portal: FC = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  if (keycloak.authenticated) {
    return <Redirect to="/" />;
  }
  const handleRegister = () => {
    keycloak.register({
      redirectUri: new URL("/register/infos", window.location.origin).href,
    });
  };
  const handleLogIn = () => {
    keycloak.login({ redirectUri: window.location.origin });
  };
  return (
    <Page>
      <Main>
        <Content>
          <section className="portal-content">
            <PortalCard
              title="Nouvel utilisateur ?"
              videoUrl="https://vod.api.video/vod/vi4iPACTUuyEbnhByo0mXNLm/mp4/source.mp4"
              onClick={handleRegister}
              buttonText={t("signUp")}
            />
            <PortalCard
              title="Déjà inscrit ?"
              videoUrl="https://vod.api.video/vod/viAoE7vI5EYbrK4IdPn5YBZ/mp4/source.mp4"
              onClick={handleLogIn}
              buttonText={t("signIn")}
            />
          </section>
        </Content>
      </Main>
    </Page>
  );
};
export default Portal;
