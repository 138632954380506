import { CheckboxInputGroupProps } from "../CheckboxInputGroup/CheckboxInputGroup";
import { RadioInputGroupProps } from "../RadioInputGroup/RadioInputGroup";
import { TextAreaInputProps } from "../TextAreaInput/TextAreaInput";
import { CheckboxInputProps } from "../CheckboxInput/CheckboxInput";
import { cloneElement, isValidElement, ReactElement } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { SelectInputProps } from "../SelectInput/SelectInput";
import { useFieldContext } from "../ArrayField/ArrayField";
import { RadioInputProps } from "../RadioInput/RadioInput";
import { ErrorMessage } from "@hookform/error-message";
import Input, { InputProps } from "../Input/Input";
import FieldLabel from "../FieldLabel/FieldLabel";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import get from "lodash/get";
import "./Field.scss";

function getColor({ error, success }) {
  if (error) return "error";
  if (success) return "success";
  return "default";
}

export interface FieldProps {
  name: string;
  label?: string;
  fullWidth?: boolean;
  success?: boolean;
  required?: boolean;
  registerOptions: RegisterOptions;
  children?: ReactElement<
    | InputProps
    | TextAreaInputProps
    | SelectInputProps
    | CheckboxInputProps
    | CheckboxInputGroupProps
    | RadioInputProps
    | RadioInputGroupProps
  >;
  helperText?: string;
}

const Field = ({
  label,
  name,
  children = <Input />,
  fullWidth = false,
  registerOptions = {},
  required = false,
  success = false,
  helperText,
  ...props
}: FieldProps) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const context = useFieldContext({ name });

  const error = get(errors, context.name);

  const color = getColor({ error, success });

  return (
    <fieldset
      className={classNames("field", {
        "field-full-width": fullWidth,
      })}
    >
      <FieldLabel name={context.name} required={required} label={label} />
      {isValidElement(children) &&
        cloneElement(children, {
          color,
          fullWidth,
          ...props,
          ...register(context.name, registerOptions),
          id: context.name,
          disabled: context.disabled,
          "aria-invalid": Boolean(error),
        })}
      <ErrorMessage
        errors={errors}
        name={context.name}
        render={({ message }) => (
          <p className="field-error-message">{t(message)}</p>
        )}
      />
      {helperText && <p className="field-helper-text">{t(helperText)}</p>}
      {!label && required && (
        <p className="field-helper-text">{t("required")}</p>
      )}
    </fieldset>
  );
};

export default Field;
