import { FileDragAndDropProvider } from "../components/DragAndDrop/DragAndDropContext";
import { UpdateCourseActionPayload } from "../domain/e-learning/e-learning.types";
import { selectCourseById } from "../domain/e-learning/e-learning.selectors";
import ThemeHeaderCard from "../components/ThemeHeaderCard/ThemeHeaderCard";
import { updateCourse } from "../domain/e-learning/e-learning.actions";
import StepNav, { Step } from "../components/StepBar/StepNav";
import CourseForm from "../components/CourseForm/CourseForm";
import FileUpload from "../components/FileUpload/FileUpload";
import { generatePath, useParams } from "react-router-dom";
import LeftSidebar from "../components/Page/LeftSidebar";
import Collapse from "../components/Collapse/Collapse";
import { useDispatch, useSelector } from "react-redux";
import Content from "../components/Page/Content";
import Topbar from "../components/Page/Topbar";
import useNavigate from "../utils/useNavigate";
import { useTranslation } from "react-i18next";
import Page from "../components/Page/Page";
import Main from "../components/Page/Main";
import mapWithId from "../utils/mapWithId";
import { FC, useMemo } from "react";
import omit from "lodash/omit";
import "./EditCourse.scss";

const EditCourse: FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ themeId: string; courseId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const onSubmit = (data: UpdateCourseActionPayload) => {
    dispatch(updateCourse(params.courseId, data));
    navigate();
  };

  const defaultValues = useSelector(
    selectCourseById(params.themeId, params.courseId),
  );

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_edit_course",
          to: generatePath("/e-learning/:id/courses/:courseId", {
            id: params.themeId,
            courseId: params.courseId,
          }),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", {
            id: params.themeId,
          }),
        },
      ]),
    [params],
  );

  return (
    <FileDragAndDropProvider>
      <Page>
        <Topbar className="edit-course-topbar">
          <StepNav steps={steps} />
        </Topbar>
        <Main>
          <LeftSidebar className="edit-course-left-sidebar">
            <ThemeHeaderCard id={params.themeId} />
            <Collapse title={t("theme_import-file-title")}>
              <FileUpload
                label={t("import_file")}
                accept={"video/*"}
                multiple
              />
            </Collapse>
          </LeftSidebar>
          <Content>
            <CourseForm
              title="e_learning_edit_course"
              onSubmit={onSubmit}
              defaultValues={omit(defaultValues, ["video", "picto"])}
            />
          </Content>
        </Main>
      </Page>
    </FileDragAndDropProvider>
  );
};

export default EditCourse;
