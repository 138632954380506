import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import {
  FieldValues,
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import { useState, ChangeEvent, useMemo, Ref, MouseEventHandler } from "react";
import SelectInput, { SelectInputProps } from "../SelectInput/SelectInput";
import { useFieldContext } from "../ArrayField/ArrayField";
import { ErrorMessage } from "@hookform/error-message";
import FieldLabel from "../FieldLabel/FieldLabel";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Input from "../Input/Input";
import "./AutocompleteField.scss";
import Chip from "../Chip/Chip";
import get from "lodash/get";

function getColor({ error, success }) {
  if (error) return "error";
  if (success) return "success";
  return "default";
}

type Option = {
  label: string;
  value: string;
};

const match = (term = "", option: Option) => {
  if (term.length === 0) return true;
  const regex = new RegExp(term, "gi");
  return [option.label, option.value].some((prop) => prop.match(regex));
};

function AutocompleteFieldOption({
  value,
  label,
  onClick,
}: {
  label: string;
  value: string;
  onClick: (value: string) => MouseEventHandler;
}) {
  return (
    <Chip key={value} onClick={onClick(value)}>
      {label}
    </Chip>
  );
}

export interface AutocompleteFieldProps extends SelectInputProps {
  name: string;
  label?: string;
  success?: boolean;
  required?: boolean;
  rules: Omit<
    RegisterOptions<FieldValues, string>,
    "setValueAs" | "disabled" | "valueAsNumber" | "valueAsDate"
  >;
  helperText?: string;
  options?: SelectInputProps["options"];
  fullWidth?: boolean;
  defaultValue?: string[];
  ref?: Ref<HTMLSelectElement>;
}

const AutocompleteField = ({
  label,
  name,
  fullWidth = false,
  required = false,
  success = false,
  helperText,
  options = [],
  placeholder,
  disabled,
  rules,
  ...props
}: AutocompleteFieldProps) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const fieldContext = useFieldContext({ name, disabled });

  const { field } = useController({
    name: fieldContext.name,
    disabled: fieldContext.disabled,
    control,
    rules,
  });

  const error = get(errors, fieldContext.name);

  const color = getColor({ error, success });

  const [open, setOpen] = useState(false);

  const values = watch(fieldContext.name, []);

  const [inputValue, setInputValue] = useState("");

  const [selectInputValues, setSelectInputValues] = useState<string[]>([]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setOpen(true);
  };

  const onSelectInputChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selected = Array.from(
      event.target.selectedOptions,
      (option) => option.value,
    );
    setSelectInputValues(selected);
    setValue(fieldContext.name, Array.from(new Set([...values, ...selected])));
  };

  const onSelectInputBlur = () => {
    setSelectInputValues([]);
  };

  const selectedOptions = useMemo(
    () => options.filter((option) => values.includes(option.value)),
    [options, values],
  );

  const restrictedOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          !selectedOptions.some(
            (selectedOption) => selectedOption.value === option.value,
          ) && match(inputValue, option),
      ),
    [inputValue, options, selectedOptions],
  );

  const onUnselectOption = (option: string) => () => {
    const selected = values.filter((value) => value !== option);
    setSelectInputValues([]);
    setValue(fieldContext.name, selected);
  };

  const { refs, context, floatingStyles } = useFloating({
    placement: "bottom-start",
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset({ mainAxis: 6 }), flip(), shift()],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    dismiss,
  ]);

  return (
    <>
      <fieldset
        className={classNames("autocomplete", {
          "autocomplete-full-width": fullWidth,
        })}
      >
        <FieldLabel
          name={fieldContext.name}
          required={required}
          label={label}
        />
        <div ref={refs.setReference} {...getReferenceProps()}>
          <Input
            fullWidth={fullWidth}
            value={inputValue}
            onChange={onInputChange}
            color={color}
            aria-invalid={Boolean(error)}
            disabled={fieldContext.disabled}
          />
          {restrictedOptions.length > 0 && open && (
            <FloatingFocusManager
              context={context}
              modal={false}
              initialFocus={-1}
            >
              <div
                ref={refs.setFloating}
                {...getFloatingProps()}
                style={floatingStyles}
              >
                <SelectInput
                  fullWidth={fullWidth}
                  multiple
                  options={restrictedOptions}
                  placeholder={placeholder}
                  value={selectInputValues}
                  onChange={onSelectInputChange}
                  onBlur={onSelectInputBlur}
                />
              </div>
            </FloatingFocusManager>
          )}
        </div>
        {selectedOptions.length > 0 && (
          <div className="autocomplete-values">
            {selectedOptions.map((selectedOption) => (
              <AutocompleteFieldOption
                key={selectedOption.value}
                value={selectedOption.value}
                label={selectedOption.label}
                onClick={onUnselectOption}
              />
            ))}
          </div>
        )}
        <ErrorMessage
          errors={errors}
          name={fieldContext.name}
          render={({ message }) => (
            <p className="field-error-message">{t(message)}</p>
          )}
        />
        {helperText && <p className="field-helper-text">{t(helperText)}</p>}
        {!label && required && (
          <p className="field-helper-text">{t("required")}</p>
        )}
      </fieldset>
      <SelectInput
        {...props}
        id={fieldContext.name}
        {...field}
        hidden
        options={options}
        color={color}
        fullWidth={fullWidth}
        multiple
      />
    </>
  );
};

export default AutocompleteField;
