import AuthProvider from "./components/AuthProvider/AuthProvider";
import ThemeProvider from "./utils/ThemeProvider/ThemeProvider";
import AppRouter from "./components/AppRouter/AppRouter";
import Snackbar from "./components/snackbar/snackbar";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { FC } from "react";
import "./i18n";

const App: FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </ThemeProvider>
      <Snackbar />
    </Provider>
  );
};

export default App;
