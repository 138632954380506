import { createCourses } from "../domain/e-learning/e-learning.actions";
import StepNav, { Step } from "../components/StepBar/StepNav";

import { FileDragAndDropProvider } from "../components/DragAndDrop/DragAndDropContext";
import { CreateCoursesActionPayload } from "../domain/e-learning/e-learning.types";
import ThemeHeaderCard from "../components/ThemeHeaderCard/ThemeHeaderCard";
import CoursesForm from "../components/CourseForm/CoursesForm";
import FileUpload from "../components/FileUpload/FileUpload";
import { generatePath, useParams } from "react-router-dom";
import LeftSidebar from "../components/Page/LeftSidebar";
import Collapse from "../components/Collapse/Collapse";
import Content from "../components/Page/Content";
import Topbar from "../components/Page/Topbar";
import useNavigate from "../utils/useNavigate";
import { useTranslation } from "react-i18next";
import Paper from "../components/Paper/Paper";
import Page from "../components/Page/Page";
import Main from "../components/Page/Main";
import mapWithId from "../utils/mapWithId";
import { useDispatch } from "react-redux";
import { FC, useMemo } from "react";
import "./AddCourse.scss";

const AddCourse: FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ themeId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const onSubmit = (data: CreateCoursesActionPayload) => {
    dispatch(createCourses(data));
    navigate();
  };

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_add_courses",
          to: generatePath("/e-learning/:id/courses/add", {
            id: params.themeId,
          }),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", {
            id: params.themeId,
          }),
        },
      ]),
    [params],
  );

  return (
    <Page>
      <FileDragAndDropProvider>
        <Topbar className="add-course-topbar">
          <StepNav steps={steps} />
        </Topbar>
        <Main>
          <LeftSidebar className="add-course-left-sidebar">
            <ThemeHeaderCard id={params.themeId} />
            <Paper fullWidth className="add-course-file-uploader">
              <Collapse title={t("theme_import-file-title")}>
                <FileUpload
                  label={t("import_file")}
                  accept={"video/*"}
                  multiple
                />
              </Collapse>
            </Paper>
          </LeftSidebar>
          <Content>
            <CoursesForm title="e_learning_add_courses" onSubmit={onSubmit} />
          </Content>
        </Main>
      </FileDragAndDropProvider>
    </Page>
  );
};

export default AddCourse;
