import { ComponentProps, FC, isValidElement, ReactNode } from "react";
import useNavigate from "../../utils/useNavigate";
import { LocationDescriptor } from "history";
import classNames from "classnames";
import "./Chip.scss";

type Size = "small" | "medium" | "large";
const sizes: Size[] = ["small", "medium", "large"];

type Color = "primary" | "dangerous";
const colors: Color[] = ["primary", "dangerous"];

type Variant = "contained" | "outlined";
const variants: Variant[] = ["contained", "outlined"];

export interface ChipProps extends ComponentProps<"button"> {
  size?: Size;
  color?: Color;
  variant?: Variant;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children?: ReactNode;
  to?: LocationDescriptor;
}

const Chip: FC<ChipProps> = ({
  size = "medium",
  color = "primary",
  variant = "outlined",
  children,
  startIcon,
  endIcon,
  className,
  to,
  ...props
}) => {
  const navigate = useNavigate(to);

  return (
    <button
      onClick={navigate}
      {...props}
      role={to ? "link" : "button"}
      className={classNames(
        "chip",
        colors.includes(color) ? `chip-${color}` : "chip-primary",
        variants.includes(variant) ? `chip-${variant}` : "chip-outlined",
        sizes.includes(size) ? `chip-${size}` : "chip-medium",
        className,
      )}
    >
      {isValidElement(startIcon) && (
        <span className="chip-start-icon">{startIcon}</span>
      )}
      {children}
      {isValidElement(endIcon) && (
        <span className="chip-end-icon">{endIcon}</span>
      )}
    </button>
  );
};

export default Chip;
