import { DefaultValues, FormProvider, useForm } from "react-hook-form";
import validateFileSize from "../../components/Field/validateFile";
import FileField from "../../components/FileInput/FileField";
import { DictionnaryEntry } from "./dictionnaryEntry";
import Button from "../../components/Button/Button";
import Field from "../../components/Field/Field";
import Paper from "../../components/Paper/Paper";
import { useTranslation } from "react-i18next";
import "./addDictionnaryEntry.scss";
import { FC } from "react";

export type DictionnaryFormProps = {
  title: string;
  onSubmit: (dictionnaryEntry: DictionnaryEntry) => void;
  defaultValues?: DefaultValues<DictionnaryEntry>;
};

const DictionnaryEntryForm: FC<DictionnaryFormProps> = ({
  title,
  defaultValues = {},
  onSubmit,
}: DictionnaryFormProps) => {
  const { t } = useTranslation();
  const methods = useForm<DictionnaryEntry>({
    mode: "onSubmit",
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="m-form add-dictionnary-entry"
        encType="multipart/form-data"
      >
        <Paper className="m-form-fields" fullWidth>
          <h2 className="m-form-fields-title fg-color-primary">{t(title)}</h2>
          <div className="m-form-fields-content m-form-fields-content-col-2">
            <Field
              name="word"
              label="word-name"
              registerOptions={{ required: "field_required" }}
            />
            <Field
              name="definition"
              label="word-definition"
              registerOptions={{ required: "field_required" }}
            />
            <div className="m-form-fields-content">
              <FileField
                name="videoId"
                inputName="video"
                label="e_learning_video_field"
                registerOptions={{
                  required: "field_required",
                  validate: validateFileSize,
                }}
                fullWidth
                type="video"
              />
            </div>
          </div>
        </Paper>
        <Paper className="m-form-actions" fullWidth>
          <Button type="submit" className="m-form-action-main">
            {t("save")}
          </Button>
          <Button color="dangerous" variant="outlined" to="/dictionnary">
            {t("cancel")}
          </Button>
        </Paper>
      </form>
    </FormProvider>
  );
};

export default DictionnaryEntryForm;
