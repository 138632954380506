import { selectCoursesByThemeId } from "../../domain/e-learning/e-learning.selectors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Course from "../Course/Course";
import { FC, Fragment } from "react";
import "./CourseList.scss";

interface CourseListProps {
  themeId?: string;
}

const CourseList: FC<CourseListProps> = ({ themeId }) => {
  const { t } = useTranslation();

  const courses = useSelector(selectCoursesByThemeId(themeId));

  if (courses.length === 0) {
    return <p className="course-list-empty">{t("Aucun cours")}</p>;
  }

  return (
    <ol className="course-list">
      {courses.map((course, index, array) => {
        const isLast = index < array.length - 1;
        return (
          <Fragment key={course.id}>
            <Course {...course} />
            {isLast && <hr />}
          </Fragment>
        );
      })}
    </ol>
  );
};

export default CourseList;
