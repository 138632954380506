import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useMemo } from "react";

import {
  selectCurrentUserKcId,
  selectPersonalInfos,
} from "../../../domain/currentUser/currentUser.selectors";
import RadioInputGroup, {
  RadioInputGroupOption,
} from "../../../components/RadioInputGroup/RadioInputGroup";
import { updateInfos } from "../../../domain/currentUser/currentUser.actions";
import { IUpdateInfos } from "../../../domain/currentUser/currentUser.types";
import palette, { PaletteColor } from "../../../utils/ThemeProvider/palette";
import type { IPersonalInformations } from "../../../domain/auth/auth.types";
import SelectInput from "../../../components/SelectInput/SelectInput";
import VideoPlayer from "../../../utils/video/VideoPlayer";
import Button from "../../../components/Button/Button";
import Field from "../../../components/Field/Field";
import Paper from "../../../components/Paper/Paper";
import Input from "../../../components/Input/Input";
import "./personal-informations.scss";

const fontFamilies = [
  "Quicksand",
  "Accessible-DfA",
  "Andika",
  "Aphont",
  "Atkinson",
  "Calibri",
  "SitKaSmall",
  "Luciole",
  "Sylexiad Serif",
  "Sylexiad Sans",
].map((value) => ({ value, label: value }));

const fontSizeOptions = ["sm", "md", "lg", "xl"].map((value) => ({
  value,
  label: value,
}));

type ThemePropertyPaletteOption = RadioInputGroupOption & PaletteColor;

const paletteOptions: ThemePropertyPaletteOption[] = [
  "light1",
  "light2",
  "dark1",
  "dark2",
].map((value) => ({
  value,
  ...palette[value],
}));

const PersonalInformations: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultValues = useSelector(selectPersonalInfos);
  const kcId = useSelector(selectCurrentUserKcId);
  const methods = useForm<IPersonalInformations>({
    defaultValues,
    criteriaMode: "all",
    shouldFocusError: false,
  });

  const handleUpdate = (personnalInformations: IPersonalInformations) => {
    const data: IUpdateInfos = {
      kcId,
      personnalInformations,
    };
    dispatch(updateInfos(data));
  };

  const handleReset = () => {
    methods.reset(defaultValues);
  };

  const fontSizeValue = useWatch({
    control: methods.control,
    name: "fontSize",
    defaultValue: defaultValues?.fontSize || "sm",
  });

  const spacingOptions = useMemo(
    () =>
      fontSizeOptions.slice(
        fontSizeOptions.findIndex(
          (fontSizeOption) => fontSizeOption.value === fontSizeValue,
        ),
      ),
    [fontSizeValue],
  );

  return (
    <FormProvider {...methods}>
      <section className="personal-informations">
        <form
          className="m-form personal-informations-form"
          onSubmit={methods.handleSubmit(handleUpdate)}
        >
          <Paper className="m-form-fields" fullWidth>
            <h2 className="m-form-fields-title fg-color-primary">
              {t("interface_settings")}
            </h2>
            <div className="m-form-fields-content">
              <Field
                label="font-family"
                name="fontFamily"
                registerOptions={{
                  required: "field_required",
                }}
                fullWidth
              >
                <SelectInput options={fontFamilies} />
              </Field>
            </div>
            <div className="m-form-fields-content m-form-fields-content-col-3">
              <Field
                label="size"
                name="fontSize"
                registerOptions={{
                  required: "field_required",
                }}
                fullWidth
              >
                <SelectInput options={fontSizeOptions} />
              </Field>
              <Field
                label="line-spacing"
                name="lineSpacing"
                registerOptions={{
                  required: "field_required",
                }}
                fullWidth
              >
                <SelectInput options={spacingOptions} />
              </Field>
              <Field
                label="word-spacing"
                name="wordSpacing"
                registerOptions={{
                  required: "field_required",
                }}
                fullWidth
              >
                <SelectInput options={spacingOptions} />
              </Field>
            </div>
            <div className="m-form-fields-content">
              <RadioInputGroup
                {...methods.register("palette")}
                options={paletteOptions}
                className="personal-informations-form-field-palette"
                renderOption={(option) => (
                  <div className="personal-informations-form-field-palette-colors">
                    <div className="personal-informations-form-field-palette-color">
                      <div className="personal-informations-form-field-palette-color-label fg-color-primary">
                        Couleur de texte
                      </div>
                      <div
                        className={`personal-informations-form-field-palette-color-value bg-color-${option.foregroundColorPrimary}`}
                      />
                    </div>
                    <div className="personal-informations-form-field-palette-color">
                      <div className="personal-informations-form-field-palette-color-label fg-color-primary">
                        Couleur de fond
                      </div>
                      <div
                        className={`personal-informations-form-field-palette-color-value bg-color-${option.backgroundColorPrimaryMain}`}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
          </Paper>
          <Paper className="m-form-fields" fullWidth>
            <h2 className="m-form-fields-title fg-color-primary">
              {t("identity")}
            </h2>
            <div className="m-form-fields-content m-form-fields-content-col-2">
              <Field
                name="lastName"
                label="lastName"
                registerOptions={{ required: "field_required" }}
                required
              />
              <Field
                name="firstName"
                label="firstName"
                registerOptions={{ required: "field_required" }}
                required
              />
              <Field
                name="address"
                label="address"
                registerOptions={{ required: "field_required" }}
                required
              />
              <Field
                name="zipcode"
                label="zipcode"
                registerOptions={{
                  required: "field_required",
                  pattern: {
                    value: /[0-9]{5}/,
                    message: t("invalid_format_zipcode"),
                  },
                }}
                required
              />
              <Field
                name="city"
                label="city"
                registerOptions={{ required: "field_required" }}
                required
              />
              <Field
                name="birthDate"
                label="birthDate"
                registerOptions={{ required: "field_required" }}
                required
              >
                <Input type="date" />
              </Field>
            </div>
          </Paper>
          <Paper className="m-form-actions" fullWidth>
            <Button type="submit" className="m-form-action-main">
              {t("save")}
            </Button>
            <Button color="dangerous" variant="outlined" onClick={handleReset}>
              {t("cancel")}
            </Button>
          </Paper>
        </form>
        <div className="personal-informations-left-sidebar">
          <VideoPlayer src="" size="medium" />
        </div>
      </section>
    </FormProvider>
  );
};
export default PersonalInformations;
