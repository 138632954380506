import {
  IAnswer,
  ICourse,
  IQuestion,
  ITheme,
  ISession,
  IExerciseBeneficiary,
  ISessionScore,
  CreateThemeActionPayload,
  UpdateThemeActionPayload,
  CreateExerciseActionPayload,
  UpdateExerciseActionPayload,
  IExercise,
  CreateCoursesActionPayload,
  UpdateCourseActionPayload,
} from "../../domain/e-learning/e-learning.types";
import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";
import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";
import requestUrlConstructor from "../utils/requestUrlConstructor";
import createFormData from "../utils/createFormData";

function ELearningApi(executor: ApiExecutorType): {
  createTheme: (theme: CreateThemeActionPayload) => Promise<{
    body: ITheme;
    status: number;
  }>;
  updateTheme: (
    id: string,
    theme: UpdateThemeActionPayload,
  ) => Promise<{
    body: ITheme;
    status: number;
  }>;
  deleteTheme: (id: string) => Promise<{
    status: number;
  }>;
  createExercise: (exercise: CreateExerciseActionPayload) => Promise<{
    body: IExercise;
    status: number;
  }>;
  getThemes: () => Promise<{
    body: ITheme[];
    status: number;
  }>;
  getDictionnaryEntries: () => Promise<{
    body: DictionnaryEntry[];
    status: number;
  }>;
  getThemeAndCourses: (id: string) => Promise<{
    status: number;
  }>;
  getThemeAndExercises: (id: string) => Promise<{
    status: number;
  }>;
  getBeneficiaryThemes: () => Promise<{
    body: ITheme[];
    status: number;
  }>;
  createCourses: (courses: CreateCoursesActionPayload) => Promise<{
    body: ICourse[];
    status: number;
  }>;
  deleteCourse: (id: string) => Promise<{
    status: number;
  }>;
  updateCourse: (
    id: string,
    course: UpdateCourseActionPayload,
  ) => Promise<{
    body: ICourse;
    status: number;
  }>;
  publishCourse: (id: string) => Promise<{
    status: number;
  }>;
  addDictionnaryEntry: (dictionnaryEntry: DictionnaryEntry) => Promise<{
    body: DictionnaryEntry;
    status: number;
  }>;
  deleteDictionnaryEntry: (dictionnaryEntryId: string) => Promise<{
    status: number;
  }>;
  updateDictionnaryEntry: (dictionnaryEntry: DictionnaryEntry) => Promise<{
    body: DictionnaryEntry;
    status: number;
  }>;
  publishAllCourses: (publishData: {
    themeId: string;
    isPublished: boolean;
  }) => Promise<{
    status: number;
  }>;
  publishAllExercices: (publishData: {
    themeId: string;
    isPublished: boolean;
  }) => Promise<{
    status: number;
  }>;
  getCourses: (id: string) => Promise<{
    status: number;
  }>;
  updateExercise(
    id: string,
    exercise: UpdateExerciseActionPayload,
  ): Promise<{
    body: IExercise;
    status: number;
  }>;
  updateQuestion: (question: Partial<IQuestion>) => Promise<{
    body: IQuestion;
    status: number;
  }>;
  updateAnswer: (answer: Partial<IAnswer>) => Promise<{
    body: IQuestion;
    status: number;
  }>;
  deleteExercise: (exerciseId: string) => Promise<{
    status: number;
  }>;
  getBeneficiaryExercises: (id: string) => Promise<{
    body: IExercise[];
    status: number;
  }>;
  getBeneficiaryPracticeSession: (data: {
    userId: string;
    themeId: string;
  }) => Promise<{
    body: ISession;
    status: number;
  }>;
  getBeneficiaryExamSession: (data: { userId: string }) => Promise<{
    body: ISession;
    status: number;
  }>;
  updateExerciseBeneficiary: (
    exerciseBeneficiary: Partial<IExerciseBeneficiary> | undefined,
  ) => Promise<{
    body: IExerciseBeneficiary;
    status: number;
  }>;
  getBeneficiaryPracticeSessionScore: (id: string) => Promise<{
    body: ISessionScore;
    status: number;
  }>;
  getBeneficiaryExamSessionScore: (id: string) => Promise<{
    body: ISessionScore;
    status: number;
  }>;
} {
  function createTheme(theme: CreateThemeActionPayload): Promise<{
    body: ITheme;
    status: number;
  }> {
    return executor.upload("/theme", createFormData(theme));
  }

  function createCourses(course: CreateCoursesActionPayload): Promise<{
    body: ICourse[];
    status: number;
  }> {
    return executor.upload("/courses", createFormData(course));
  }

  function deleteCourse(id: string): Promise<{
    status: number;
  }> {
    return executor.del(`/courses/${id}`);
  }

  function publishCourse(id: string): Promise<{
    status: number;
  }> {
    return executor.post(`/courses/publish/${id}`);
  }

  function updateTheme(
    id: string,
    theme: UpdateThemeActionPayload,
  ): Promise<{
    body: ITheme;
    status: number;
  }> {
    return executor.putUpload(`/theme/${id}`, createFormData(theme));
  }

  function deleteTheme(id: string): Promise<{
    status: number;
  }> {
    return executor.del(`/theme/${id}`);
  }

  function createExercise(exercise: CreateExerciseActionPayload): Promise<{
    body: IExercise;
    status: number;
  }> {
    return executor.upload("/exercises", createFormData(exercise));
  }

  function getThemes(): Promise<{
    body: ITheme[];
    status: number;
  }> {
    return executor.get(`/theme`);
  }

  function getDictionnaryEntries(): Promise<{
    body: DictionnaryEntry[];
    status: number;
  }> {
    return executor.get(`/dictionnary`);
  }

  function getThemeAndCourses(id: string): Promise<{
    body: ITheme;
    status: number;
  }> {
    return executor.get(`/theme/courses/${id}`);
  }

  function getThemeAndExercises(id: string): Promise<{
    body: ITheme;
    status: number;
  }> {
    return executor.get(`/theme/exercises/${id}`);
  }

  function getBeneficiaryThemes(): Promise<{
    body: ITheme[];
    status: number;
  }> {
    return executor.get(`/theme/beneficiary`);
  }

  function addDictionnaryEntry(dictionnaryEntry: DictionnaryEntry): Promise<{
    body: DictionnaryEntry;
    status: number;
  }> {
    return executor.upload("/dictionnary", createFormData(dictionnaryEntry));
  }

  function deleteDictionnaryEntry(dictionnaryEntryId: string): Promise<{
    status: number;
  }> {
    return executor.del(`/dictionnary/${dictionnaryEntryId}`);
  }

  function updateDictionnaryEntry(dictionnaryEntry: DictionnaryEntry): Promise<{
    body: DictionnaryEntry;
    status: number;
  }> {
    return executor.putUpload("/dictionnary", createFormData(dictionnaryEntry));
  }

  function updateCourse(
    id: string,
    course: UpdateCourseActionPayload,
  ): Promise<{
    body: ICourse;
    status: number;
  }> {
    return executor.putUpload(`/courses/${id}`, createFormData(course));
  }

  function publishAllCourses(publishData: {
    themeId: string;
    isPublished: boolean;
  }): Promise<{
    status: number;
  }> {
    return executor.post("/courses/publish-all-by-theme", publishData);
  }

  function publishAllExercices(publishData: {
    themeId: string;
    isPublished: boolean;
  }): Promise<{
    status: number;
  }> {
    return executor.post("/exercises/publish-all-by-theme", publishData);
  }

  function getCourses(id: string): Promise<{
    body: ICourse[];
    status: number;
  }> {
    return executor.get(`/courses/beneficiary/${id}`);
  }

  function deleteExercise(exerciseId: string): Promise<{
    status: number;
  }> {
    const url = `/exercises/${exerciseId}`;
    return executor.del(url);
  }

  function updateExercise(
    id: string,
    exercise: UpdateExerciseActionPayload,
  ): Promise<{
    body: IExercise;
    status: number;
  }> {
    return executor.putUpload(`/exercises/${id}`, createFormData(exercise));
  }

  function updateQuestion(
    question: Partial<IQuestion>,
  ): Promise<{ body; status: number }> {
    return executor.put("/questions", createFormData(question));
  }
  function updateAnswer(
    answer: Partial<IAnswer>,
  ): Promise<{ body; status: number }> {
    return executor.put("/answers", createFormData(answer));
  }

  function getBeneficiaryExercises(id: string): Promise<{
    body: IExercise[];
    status: number;
  }> {
    return executor.get(`/exercises/beneficiary/${id}`);
  }

  function getBeneficiaryPracticeSession(data: {
    userId: string;
    themeId: string;
  }): Promise<{
    body: ISession;
    status: number;
  }> {
    const url = "/practice-session/beneficiary";
    const objectParams = {
      userId: data.userId,
      themeId: data.themeId,
    };
    const urlCreated = requestUrlConstructor(url, objectParams);
    return executor.get(urlCreated);
  }

  function getBeneficiaryExamSession(data: { userId: string }): Promise<{
    body: ISession;
    status: number;
  }> {
    const url = "/examen/beneficiary";
    const objectParams = {
      userId: data.userId,
    };
    const urlCreated = requestUrlConstructor(url, objectParams);
    return executor.get(urlCreated);
  }

  function updateExerciseBeneficiary(
    answer: Partial<IExerciseBeneficiary> | undefined,
  ): Promise<{ body: IExerciseBeneficiary; status: number }> {
    return executor.put("/exercise-beneficiary/beneficiary", answer);
  }

  function getBeneficiaryPracticeSessionScore(id: string): Promise<{
    body: ISessionScore;
    status: number;
  }> {
    return executor.get(`/practice-session/beneficiary/${id}`);
  }

  function getBeneficiaryExamSessionScore(id: string): Promise<{
    body: ISessionScore;
    status: number;
  }> {
    return executor.get(`/examen/beneficiary/${id}`);
  }

  return {
    createTheme,
    getThemes: getThemes,
    getThemeAndCourses,
    getThemeAndExercises,
    deleteTheme,
    updateTheme,
    getBeneficiaryThemes,
    createCourses,
    deleteCourse,
    updateCourse,
    publishCourse,
    publishAllCourses,
    publishAllExercices,
    getCourses,
    createExercise,
    updateExercise,
    updateQuestion,
    updateAnswer,
    deleteExercise,
    getBeneficiaryExercises,
    getBeneficiaryPracticeSession,
    getBeneficiaryExamSession,
    updateExerciseBeneficiary,
    getBeneficiaryPracticeSessionScore,
    getBeneficiaryExamSessionScore,
    addDictionnaryEntry,
    deleteDictionnaryEntry,
    updateDictionnaryEntry,
    getDictionnaryEntries,
  };
}

export default ELearningApi;
