import { ComponentProps, FC, forwardRef, Ref } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./SelectInput.scss";

type Option = {
  label: string;
  value: string;
};

export interface SelectInputProps extends ComponentProps<"select"> {
  fullWidth?: boolean;
  color?: "default" | "error" | "success";
  options?: Option[];
  ref?: Ref<HTMLSelectElement>;
}

const SelectInput: FC<SelectInputProps> = forwardRef<
  HTMLSelectElement,
  SelectInputProps
>(
  (
    {
      options = [],
      multiple,
      size,
      fullWidth = false,
      color = "default",
      ...props
    }: SelectInputProps,
    ref,
  ) => {
    const { t } = useTranslation();

    const extended = (size && size > 1) || multiple;

    return (
      <div
        className={classNames("select-input-wrapper", {
          "select-input-wrapper-full-width": fullWidth,
          "select-input-wrapper-extended": extended,
        })}
      >
        <select
          {...props}
          ref={ref}
          className={classNames(
            "select-input",
            {
              "select-input-full-width": fullWidth,
              "select-input-extended": extended,
            },
            `select-input-${color}`,
          )}
          size={size}
          multiple={multiple}
        >
          {!extended && <option className="select-input-option" value="" />}
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              className="select-input-option"
            >
              {option.label ? t(option.label) : option}
            </option>
          ))}
        </select>
      </div>
    );
  },
);

SelectInput.displayName = "SelectInput";

export default SelectInput;
