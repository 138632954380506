import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import IconButton from "../IconButton/IconButton";
import { FC, ReactNode, useState } from "react";
import "./Collapse.scss";

interface CollapseProps {
  title: ReactNode;
  children: ReactNode;
}

const Collapse: FC<CollapseProps> = ({ children, title }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="collapse">
      <div
        className="collapse-header"
        onClick={toggle}
        onKeyDown={toggle}
        role="button"
        tabIndex={-1}
      >
        <div className="collapse-title">{title}</div>
        <IconButton>{open ? <IoChevronUp /> : <IoChevronDown />}</IconButton>
      </div>
      {open && <div className="collapse-content">{children}</div>}
    </div>
  );
};

export default Collapse;
