import {
  selectCourseOptionsByThemeId,
  selectExerciseById,
} from "../domain/e-learning/e-learning.selectors";
import { FileDragAndDropProvider } from "../components/DragAndDrop/DragAndDropContext";
import CheckboxInputGroup from "../components/CheckboxInputGroup/CheckboxInputGroup";
import { UpdateExerciseActionPayload } from "../domain/e-learning/e-learning.types";
import ThemeHeaderCard from "../components/ThemeHeaderCard/ThemeHeaderCard";
import { updateExercise } from "../domain/e-learning/e-learning.actions";
import ExerciseForm from "../components/ExerciseForm/ExerciseForm";
import StepNav, { Step } from "../components/StepBar/StepNav";
import FileUpload from "../components/FileUpload/FileUpload";
import IconButton from "../components/IconButton/IconButton";
import { generatePath, useParams } from "react-router-dom";
import LeftSidebar from "../components/Page/LeftSidebar";
import { FormProvider, useForm } from "react-hook-form";
import Collapse from "../components/Collapse/Collapse";
import { useDispatch, useSelector } from "react-redux";
import Content from "../components/Page/Content";
import Topbar from "../components/Page/Topbar";
import useNavigate from "../utils/useNavigate";
import { useTranslation } from "react-i18next";
import Paper from "../components/Paper/Paper";
import Title from "../components/Page/Title";
import Page from "../components/Page/Page";
import Main from "../components/Page/Main";
import mapWithId from "../utils/mapWithId";
import { IoCreate } from "react-icons/io5";
import { FC, useMemo } from "react";
import "./EditExercise.scss";

const EditExercise: FC = () => {
  const { t } = useTranslation();

  const params = useParams<{ themeId: string; exerciseId: string }>();

  const courses = useSelector(selectCourseOptionsByThemeId(params.themeId));

  const defaultValues = useSelector(
    selectExerciseById(params.themeId, params.exerciseId),
  );

  const methods = useForm<UpdateExerciseActionPayload>({
    mode: "onSubmit",
    defaultValues,
  });

  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);

  const dispatch = useDispatch();

  const onSubmit = (data: UpdateExerciseActionPayload) => {
    dispatch(updateExercise(params.exerciseId, data));
    navigate();
  };

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_edit_course",
          to: generatePath("/e-learning/:id/courses/add", {
            id: params.themeId,
          }),
        },
        {
          label: "e_learning_edit_exercise",
          to: generatePath("/e-learning/:id/exercises/:exerciseId", {
            id: params.themeId,
            exerciseId: params.exerciseId,
          }),
        },
      ]),
    [params],
  );

  return (
    <FormProvider {...methods}>
      <FileDragAndDropProvider>
        <Page>
          <Topbar className="edit-exercise-topbar">
            <StepNav steps={steps} />
          </Topbar>
          <Main>
            <LeftSidebar className="edit-exercise-left-sidebar">
              <ThemeHeaderCard id={params.themeId} />
              <Paper fullWidth className="edit-exercise-course-selector">
                <CheckboxInputGroup
                  name="courses"
                  label={t("select_courses")}
                  options={courses}
                  required
                  defaultValue={defaultValues.courses}
                  renderOption={(option) => (
                    <IconButton
                      to={`/e-learning/${params.themeId}/courses/${option.value}`}
                    >
                      <IoCreate />
                    </IconButton>
                  )}
                />
              </Paper>
              <Collapse title={t("theme_import-file-title")}>
                <FileUpload
                  label={t("import_file")}
                  accept={"video/*"}
                  multiple
                />
              </Collapse>
            </LeftSidebar>
            <Content>
              <Title className="">{t("e_learning_edit_exercise")}</Title>
              <ExerciseForm onSubmit={onSubmit} />
            </Content>
          </Main>
        </Page>
      </FileDragAndDropProvider>
    </FormProvider>
  );
};

export default EditExercise;
