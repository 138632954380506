import { UpdateThemeActionPayload } from "../../domain/e-learning/e-learning.types";
import { selectThemeById } from "../../domain/e-learning/e-learning.selectors";
import { updateTheme } from "../../domain/e-learning/e-learning.actions";
import ThemeForm from "../../components/ThemeForm/ThemeForm";
import { useDispatch, useSelector } from "react-redux";
import useNavigate from "../../utils/useNavigate";
import { useParams } from "react-router-dom";
import { FC } from "react";

const EditTheme: FC = () => {
  const { id } = useParams<{ id: string }>();
  const defaultValues = useSelector(selectThemeById(id));
  const dispatch = useDispatch();
  const navigate = useNavigate("/e-learning");

  const onSubmit = (data: UpdateThemeActionPayload) => {
    dispatch(updateTheme(id, data));
    navigate();
  };

  return (
    <ThemeForm
      mode="edit"
      title="e_learning_edit"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
};

export default EditTheme;
