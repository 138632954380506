import { upsertAdmin } from "../../../domain/manageUsers/manageUsers.actions";
import myAdminAddImage from "../../../assets/icons/addAdmin.svg";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FC, useState } from "react";

import "./add-admin-card.scss";

const AddCardAdmin: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    criteriaMode: "all",
  });

  const toggleShowComponent = () => {
    setShowComponent(!showComponent);
    reset();
  };

  const handleOnFocus = (event) => {
    event.target.setAttribute("autocomplete", "off");
  };

  const onSubmit = (data) => {
    const adminToAdd = {
      email: data.email,
      isAdministrator: true,
      isAdminPrincipal: false,
      permissions: {
        accessChatbot: false,
        modifySupport: false,
        createAdmin: false,
        pedagogicSupervision: false,
      },
    };
    dispatch(upsertAdmin(adminToAdd));
    setShowComponent(!showComponent);
  };
  return (
    <div className="admin-card-item add-card">
      {!showComponent && (
        <button
          type="button"
          onClick={toggleShowComponent}
          className="card-button-add"
          aria-label={t("add_admin")}
        >
          <img src={myAdminAddImage} alt={t("add_admin")} />
        </button>
      )}
      {showComponent && (
        <div className="add-card-form">
          <h3 className="add-card-title">{t("add_admin")}</h3>
          <span className="add-card-description">
            {t("add_admin_description")}
          </span>
          <form onSubmit={handleSubmit(onSubmit)} className="m-form">
            <div className="form-group full-size">
              <label htmlFor="email">
                {t("Email")}
                <input
                  type="text"
                  id="email"
                  {...register("email", {
                    required: t("field_required"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("Email is not valid"),
                    },
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                  placeholder={t("email_format")}
                  onFocus={(e) => handleOnFocus(e)}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <span className="text-error" key={type}>
                        {message}
                      </span>
                    ))
                  }
                />
              </label>
            </div>
            <div className="form-actions">
              <input
                className="m-button green"
                type="submit"
                value={t("Record") as string}
              />
              <input
                className="m-button red"
                type="reset"
                value={t("cancel") as string}
                onClick={toggleShowComponent}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddCardAdmin;
