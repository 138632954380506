import { selectThemeById } from "../../domain/e-learning/e-learning.selectors";
import CourseList from "../CourseList/CourseList";
import { useTranslation } from "react-i18next";
import { IoAddCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import Button from "../Button/Button";
import Paper from "../Paper/Paper";
import { FC } from "react";
import "./Theme.scss";

interface ThemeProps {
  id: string;
}

const Theme: FC<ThemeProps> = ({ id }) => {
  const { t } = useTranslation();
  const theme = useSelector(selectThemeById(id));
  if (!theme) {
    return (
      <div className="theme">
        <div className="theme-header">
          <h2 className="theme-title">{t("Sélectionne une thématique")}</h2>
        </div>
      </div>
    );
  }
  return (
    <Paper className="theme" fullHeight fullWidth>
      <div className="theme-header">
        <h2 className="theme-title">{theme.title}</h2>
        <Button
          to={`/e-learning/${theme.id}/courses/add`}
          variant="outlined"
          startIcon={<IoAddCircle />}
        >
          {t("e_learning_add_courses")}
        </Button>
      </div>
      <hr />
      <div className="theme-content">
        <CourseList themeId={id} />
      </div>
    </Paper>
  );
};

export default Theme;
