import { selectCurrentUserId } from "../../../domain/currentUser/currentUser.selectors";
import TextAreaInput from "../../../components/TextAreaInput/TextAreaInput";
import { saveMessage } from "../../../domain/contact/message.actions";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../components/Button/Button";
import { useSelector, useDispatch } from "react-redux";
import Paper from "../../../components/Paper/Paper";
import Field from "../../../components/Field/Field";
import { useTranslation } from "react-i18next";
import "./chatbot-beneficary.scss";
import { FC } from "react";

const ChatbotBeneficary: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onSubmit",
    defaultValues: { message: "" },
  });
  const currentUserId = useSelector(selectCurrentUserId) as unknown as number;

  const onSubmit = (data) => {
    dispatch(saveMessage({ text: data.message, sender: currentUserId }));
    methods.reset({ message: "" });
  };

  return (
    <div className="chatbot-beneficiary">
      <h1 className="m-typography-h1 text-color-primary">{t("chatbot")}</h1>
      <h2 className="m-typography-h2 text-color-primary">
        {t("needSomeInformation")}
        <br />
        {t("contactUs")}
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="m-form">
          <Paper className="m-form-fields" fullWidth>
            <div className="m-form-fields-content">
              <Field
                name="message"
                label="yourMessage"
                registerOptions={{ required: true }}
                fullWidth
              >
                <TextAreaInput rows={12} />
              </Field>
            </div>
          </Paper>
          <Paper className="m-form-actions" fullWidth>
            <Button
              type="submit"
              className="m-form-action-main"
              disabled={!methods.formState.isDirty}
            >
              {t("send")}
            </Button>
          </Paper>
        </form>
      </FormProvider>
    </div>
  );
};
export default ChatbotBeneficary;
