import { UpdateCourseActionPayload } from "../../domain/e-learning/e-learning.types";
import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";
import { selectDictionnary } from "../../domain/e-learning/e-learning.selectors";
import AutocompleteField from "../AutocompleteField/AutocompleteField";
import { useForm, FormProvider, DefaultValues } from "react-hook-form";
import validateFileSize from "../Field/validateFile";
import FileField from "../FileInput/FileField";
import { useTranslation } from "react-i18next";
import { AppState } from "../../redux/types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../Button/Button";
import Field from "../Field/Field";
import Paper from "../Paper/Paper";
import Title from "../Page/Title";
import "./CourseForm.scss";
import { FC } from "react";

export type CourseFormValues = UpdateCourseActionPayload;

interface CourseFormProps {
  title: string;
  themeId?: string;
  onSubmit: (data: CourseFormValues) => void;
  defaultValues?: DefaultValues<CourseFormValues>;
}

const CourseForm: FC<CourseFormProps> = ({
  title,
  onSubmit,
  defaultValues = {},
}) => {
  const { t } = useTranslation();

  const params = useParams<{ themeId: string; courseId: string }>();

  const methods = useForm<CourseFormValues>({
    reValidateMode: "onChange",
    defaultValues,
  });

  const dictionnary = useSelector<AppState, DictionnaryEntry[] | undefined>(
    selectDictionnary,
  );

  const dictionnaryOptions = dictionnary
    ? dictionnary.map((entry) => ({
        label: entry.word,
        value: entry.id,
      }))
    : [];

  const onSubmitWithId = (data: UpdateCourseActionPayload) => {
    onSubmit({ ...data, themeId: params.themeId, order: 2 });
  };

  const onPublish = (data: CourseFormValues) => {
    onSubmit({
      ...data,
      themeId: params.themeId,
      order: 2,
      isPublished: !defaultValues.isPublished,
    });
  };

  return (
    <FormProvider {...methods}>
      <form className="m-form" onSubmit={methods.handleSubmit(onSubmitWithId)}>
        <Title className="">{t(title)}</Title>
        <Paper fullWidth className="m-form-fields">
          <h2 className="m-typography-h2">
            {defaultValues.title || t("e_learning_add_course")}
          </h2>
          <Field
            name="title"
            label="title"
            registerOptions={{ required: "field_required" }}
            fullWidth
          />
          <Field
            name="description"
            label="course_description"
            registerOptions={{ required: "field_required" }}
            fullWidth
          />
          <div className="m-form-fields-content m-form-fields-content-col-2">
            <FileField
              name="videoId"
              inputName="video"
              label="add_video"
              registerOptions={{
                required: "field_required",
                validate: validateFileSize,
              }}
              fullWidth
              type="video"
            />
            <FileField
              name="srt"
              inputName="srt"
              label="add_alt_text"
              registerOptions={{
                required: "field_required",
                validate: validateFileSize,
              }}
              fullWidth
              type="srt"
            />
            <FileField
              name="pictoName"
              inputName="picto"
              label="add_mental_map"
              registerOptions={{
                required: "field_required",
                validate: validateFileSize,
              }}
              fullWidth
              type="image"
            />
            <AutocompleteField
              name="dictionnary"
              label="add_dictionnary_entry"
              rules={{ required: "field_required" }}
              options={dictionnaryOptions}
              fullWidth
            />
          </div>
        </Paper>
        <Paper fullWidth className="m-form-actions">
          <Button onClick={methods.handleSubmit(onPublish)}>
            {defaultValues && defaultValues.isPublished
              ? t("unpublish")
              : t("publish")}
          </Button>
          <Button type="submit">{t("save")}</Button>
          <Button
            to={`/e-learning/${params.themeId}/exercises/add}`}
            className="m-form-action-main"
            disabled={Object.keys(defaultValues).length === 0}
          >
            {t("e_learning_add_exercises")}
          </Button>
          <Button
            to={`/e-learning?theme="${params.themeId}"`}
            color="dangerous"
            variant="outlined"
          >
            {t("cancel")}
          </Button>
        </Paper>
      </form>
    </FormProvider>
  );
};

export default CourseForm;
