import { selectSnackBar } from "../../domain/interactionsWithUsers/interactionsWithUsers.selectors";
import { showSnackBar } from "../../domain/interactionsWithUsers/interactionsWithUsers.actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect } from "react";

import "./snackbar.scss";

const Snackbar: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackBar = useSelector(selectSnackBar);

  useEffect(() => {
    if (snackBar.status) {
      setTimeout(() => {
        const snackbar = {
          status: false,
          type: "",
          message: "",
          error: "",
        };
        dispatch(showSnackBar(snackbar));
      }, 5000);
    }
  }, [dispatch, snackBar]);

  if (!snackBar.status) {
    return null;
  }

  return (
    <div>
      <div
        role={snackBar.type === "error" ? "alert" : "status"}
        className={`snackbar ${snackBar.type === "error" ? "error" : "success"}`}
      >
        <p className="snackbar-text">{t(snackBar.message)}</p>
      </div>
    </div>
  );
};

export default Snackbar;
